import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import { Link } from "react-router-dom";

function Deposit({ socket, match: { params: deposit_id }, location }) {
  const params = new URLSearchParams(location.search);
  const amount = params.get("amount");
  let public_key = "BC1YLgnfV4sFjFaY4mQtacvwDbyR4QeyzmUNbXsZicdspep5jvAxCnt";
  const [depositStatus, setDepositStatus] = React.useState("pending");
  const [copyButtonText, setCopyButtonText] = React.useState("Copy");
  const handleCopy = () => {
    navigator.clipboard
      .writeText(public_key)
      .then(() => {
        setCopyButtonText("Copied");
        setTimeout(() => {
          setCopyButtonText("Copy");
        }, 4000);
      })
      .catch((err) => console.log("ERROR IN COPYING: ", err));
  };

  React.useEffect(() => {
    if (socket === null) {
      location.reload();
    }
    console.log("socket: ", socket, "<- socket");
    socket.emit("join_deposit_watch", deposit_id);
    socket.on("deposit_status", (status) => {
      setDepositStatus(status);
      window.onbeforeunload = null;
    });
    window.onbeforeunload = () => {
      return;
    }
    return function cleanUp() {
      socket.emit("leave_deposit_watch", deposit_id);
    };
  }, [deposit_id]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card style={{ maxWidth: "600px", marginTop: "10%" }}>
        <CardContent>
          <Typography variant="h6">
            Please send {amount} bitclout(s) to following Bitclout account to complete the
            transaction and wait for confirmation.
          </Typography>
          <br/>
          <Grid container spacing={3}>
            <Grid item sm={12} md={9}>
              <TextField
                disabled
                label="Public Key"
                variant="outlined"
                fullWidth
                multiline
                value={public_key}
              />
            </Grid>
            <Grid
              item
              sm={12}
              md={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                fullWidth
                style={
                  copyButtonText === "Copied"
                    ? { backgroundColor: "lightgreen" }
                    : {}
                }
                onClick={handleCopy}
              >
                {copyButtonText}
              </Button>
            </Grid>
          </Grid>
          <br/>
          {depositStatus === "pending" ? (
            <Alert severity="info">
              <AlertTitle>Waiting for Transaction</AlertTitle>
            </Alert>
          ) : null}
          {depositStatus === "verified" ? (
            <Alert severity="success">
              <AlertTitle>Transaction Verified</AlertTitle>
            </Alert>
          ) : null}
          {depositStatus === "failed" ? (
            <Alert severity="error">
              <AlertTitle>Transaction Failed</AlertTitle>
            </Alert>
          ) : null}
          <br/>
          {depositStatus !== "pending" ? (
            <Link to="/lobby">Back To Lobby</Link>
          ) : null}
        </CardContent>
      </Card>
    </div>
  );
}

export default Deposit;
