import React, { Component } from "react";
import cx from "classnames";
import tableStyles from "../Table/style.css";
import "../../enk.css";
import styles from "./Lobby.module.css";
import userLogo from "../../images/user.png";
import chipAmountImg from "../../images/chip-amount.png";
import circleIcon from "../../images/circle-icon.png";
import playerIcon from "../../images/player-icon.png";
import { Link, Redirect } from "react-router-dom";
import { API } from "../../backend";
import {signout} from "../../auth/helper";
import BetaMessage from "../BetaMessage/BetaMessage.component";
import RestrictMobile from "../RestrictMobile/RestrictMobile";



export default class Lobby extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lobbyTables: null,
      dataLoaded: false,
      showCreateForm: false,
      showJoinForm: false,
      tableName: "",
      smallBlind: 10,
      isPrivate: true,
      password: "",
      total_chips: 0,
      username: "",
      name: "",
      tableId: null,
      cardClass: "card-back",
      success: false,
      redirectToHome:false
    };
  }

  componentWillMount() {
    // //
    // if(!this.props.isSocket){
    //   window.location.reload();
    // }
  }

  fetchLobbyData = () => {
    fetch(`${API}/lobby-data`)
      .then((response) => response.json())
      .then((data) => {
        console.log("LOBBY DETAILS FECHED!!");

        this.setState({
          lobbyTables: data,
        });
      })
      .catch((err) => console.log(err));
  };

  setCardClass = (cardClass) =>{
    this.setState({cardClass});
  }

  renderLobbyData = () => {
    const { lobbyTables } = this.state;
    let uiItems = [];
    console.log("tables: ", lobbyTables);
    if (lobbyTables !== null) {
      Object.values(lobbyTables).map((table) => {
        if (table !== null) {
          uiItems.push(
            <div key={table.id} className="lobby-table">
              {/*  onClick={()=>{window.close();}} target="_blank" */}
              <Link className="link" to={`/table-5/${table.id}`}>
                <div className="table-info">
                  <span>{table.name}</span>
                  <span>
                    <img src={playerIcon} alt="player" />
                    {table.playersSeatedCount}/5{" "}
                    <img src={circleIcon} alt="2K" />
                    2K
                  </span>
                </div>
                <div className="table-user">
                  <img src={userLogo} alt="Player" />
                  <span>Player</span>
                </div>
              </Link>
            </div>
          );
        }
      });
      return uiItems;
    }
  };

  getUserData = () => {
    const user = JSON.parse(localStorage.getItem("jwt")).user;
    fetch(`${API}/user/${user._id}`)
      .then((response) => response.json())
      .then((data) => this.setState(data))
      .catch((err) => console.log(err));
  };

  componentDidMount() {
    this.getUserData();
    this.fetchLobbyData();
    console.log("components mounted");
  }

  handleChange = (name) => (event) => {
    this.setState({ ...this.state, [name]: event.target.value });
  };

  handlePrivate = (event) => {
    console.log("CHECKED: ", event.target.checked);
    this.setState({ isPrivate: event.target.checked });
  };

  handleJoinRoom = (event) => {
    event.preventDefault();
    fetch(`${API}/table/join/${this.state.tableId}`)
     .then(response => response.json())
     .then(data => {
       console.log("JOIN THIS ROOM: ",data);
       this.setState(data);
    })
  }

  handleCreateRoom = (event) => {
    event.preventDefault();
    const data = {
      name: this.state.tableName,
      smallBlind: this.state.smallBlind,
      isPrivate: this.state.isPrivate,
      password: this.state.password,
    };
    fetch(`${API}/table/create`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(data => {
        console.log("THIS ROOM CREATED",data);
        this.setState(data);
      })
      .catch((err) => console.log(err));
    this.setState({ showCreateForm: false });
  };

  joinRoomForm = () => {
    return (
      <div className={this.state.showJoinForm ? "modal-wrap" : "hide"}>
        <div id="join-room-form" className="join-room-form">
          <h2>Join Table</h2>
          <form action="#" onSubmit={this.handleJoinRoom}>
            <input
              required
              type="text"
              onChange={this.handleChange("tableId")}
              placeholder="Table ID"
            />
            {/* <input
              type="text"
              placeholder="password"
              onChange={this.handleChange("password")}
              className={this.state.isPrivate ? "password-field" : "hide"}
            /> */}

            <button type="submit" className="create-room-modal-button btn1">
              Join Table
            </button>
          </form>
        </div>
        <div
          className="curtain"
          onClick={() => this.setState({ showJoinForm: false })}
        ></div>
      </div>
    );
  }

  createRoomForm = () => {
    return (
      <div className={this.state.showCreateForm ? "modal-wrap" : "hide"}>
        <div id="create-room-form" className="create-room-form">
          <h2>Create Table</h2>
          <form action="#" onSubmit={this.handleCreateRoom}>
            <input
              required
              type="text"
              onChange={this.handleChange("tableName")}
              placeholder="Room name"
            />
            <input
              required
              type="range"
              min={5}
              max={40}
              step={5}
              onChange={this.handleChange("smallBlind")}
              className="js-range-slider"
              name="my_range"
              value={this.state.smallBlind}
            />
            <div>
              {this.state.smallBlind}/{this.state.smallBlind * 2}
            </div>
            <br />
            
            {/* <span className="mySp">pick one card style: </span>
            <div></div> */}
            {/* <div onClick={()=>this.setCardClass("card-back1")} className="card main-card custom-card-back card-back1"></div>
            <div onClick={()=>this.setCardClass("card-back2")} className="card main-card custom-card-back card-back2"></div>
            <div onClick={()=>this.setCardClass("card-back3")} className="card main-card custom-card-back card-back3"></div>
            <div onClick={()=>this.setCardClass("card-back4")} className="card main-card custom-card-back card-back4"></div> */}
            {/* <input
              type="checkbox"
              onChange={this.handlePrivate}
              className="mybox"
            /> */}
            {/* <input
              type="text"
              placeholder="password"
              onChange={this.handleChange("password")}
              className={this.state.isPrivate ? "password-field" : "hide"}
            /> */}
            {/* <div className="password-field">
                  <span>Password:</span>
                  <input type="text" maxlength="1" />
                  <input type="text" maxlength="1" />
                  <input type="text" maxlength="1" />
                  <input type="text" maxlength="1" />
                  <input type="checkbox" className="mybox" />
              </div> */}

            <button type="submit" className="create-room-modal-button btn1">
              Create Table
            </button>
          </form>
        </div>
        <div
          className="curtain"
          onClick={() => this.setState({ showCreateForm: false })}
        ></div>
      </div>
    );
  };

  lobbySideBar() {
    return (
      <div className="lobby-sidebar">
        <div className="swiper-container">
          {/* <!-- Additional required wrapper --> */}
          <div className="swiper-wrapper">
            {/* <!-- Slides --> */}
            <div className="swiper-slide-active">ALL</div>
            <div className="swiper-slide">1$/2$</div>
            <div className="swiper-slide">10$/20$</div>
            <div className="swiper-slide">20$/40$</div>
            <div className="swiper-slide">50$/100$</div>
            <div className="swiper-slide">1K/2K</div>
            <div className="swiper-slide">2K/4K</div>
            <div className="swiper-slide">5K/10K</div>
            <div className="swiper-slide">10K/20K</div>
            <div className="swiper-slide">20K/40K</div>
            <div className="swiper-slide">40K/80K</div>
          </div>
        </div>
      </div>
    );
  }

  logOut = () =>{
    signout(()=>{
      this.setState({redirectToHome: true}); 
    })
  }

  addMoreChips = () =>{
    const user = JSON.parse(localStorage.getItem("jwt")).user;
    fetch(`${API}/user/addChips`,{
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: user.username
      })
    })
    .then((response) => response.json())
      .then((data) =>{
        if(data.success){
          alert(data.msg);
        }else{
          alert(data.error);
        }
      })
      .catch((err) => alert("can't update chips."));
  }

  restrictView = () => {
    if(window.innerWidth <= 700){
      console.log("Mobile restriction!",window.innerWidth);
      return <RestrictMobile />
    }else{
      console.log("WIDE SCREEN!");
      return (
        <div>
        <div className="betaMessage-wrapper">
          <BetaMessage />
        </div>
        {console.log("test style: ", styles["logoutButton"])}
        {this.state.redirectToHome && <Redirect to="/" />}
        {this.state.success && <Redirect to={`/table-5/${this.state.tableId}`} />}
        <button className={styles.logoutButton} onClick={this.logOut}><strong>Log out</strong></button>
        <button className="addMoreChipsButton btn1" onClick={this.addMoreChips}>Add More Chips</button>
        <div className="header">
          <div className="header-user">
            <img src={userLogo} alt="" />
            <span>{this.state.username}</span>
            {/* <span>
            <img height="30px" width="30px" src={caretDown} />
            </span> */}
          </div>
          <div className="header-amount">
            <img src={chipAmountImg} alt="" />
            <span>{this.state.total_chips}$</span>
          </div>
        </div>
        <div className="lobby">
          {/* {this.lobbySideBar()} */}
          {/* following one is temp sidebar */}
          <div className="lobby-sidebar">
          <div>
              <button
                onClick={() => this.setState({ showCreateForm: true })}
                className="create-room btn1"
              >
                Create Table
              </button>
              <span>OR</span>
              <button 
                className="play-now btn1"
                onClick={() => this.setState({ showJoinForm: true})}
              >
                Join Table
              </button>
            </div>
          </div>
          <div className="lobby-container">
            
            <h2>All Public Tables: </h2>
            {this.renderLobbyData()}
          </div>
          {this.createRoomForm()}
          {this.joinRoomForm()}
        </div>
      </div>
      )
    }
  }

  render() {
    return (this.restrictView());
  }
}
