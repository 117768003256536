import React, { Component } from "react";
import "./App.css";
import Table from "./components/Table/Table.component";
import Lobby from "./components/Lobby/Lobby.component";
import { BrowserRouter, Route, Link, Redirect, Switch } from "react-router-dom";
import Signup from "./components/user/signup.component";
import Signin from "./components/user/signin.component";
import openSocket from "socket.io-client";
import { API } from "./backend";
import PrivateRoute from "./auth/helper/ProtectedRoutes";
import AdminRoute from "./auth/helper/AdminRoute";
import Poker from "./components/Poker/Poker.component";
import OnlinePlayers from "./components/Admin/OnlinePlayers";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import Deposit from "./components/Deposit";

const trackingId = "UA-169116358-1"; //our google analytics tracking id
ReactGA.initialize(trackingId);

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen((location) => {
  try {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  } catch (err) {
    console.log(err);
  }
});

let jwt = JSON.parse(localStorage.getItem("jwt"));
let socket = null;
if (jwt !== null) {
  socket = openSocket(API);
  socket.on("connect", () => {
    socket
      .emit("authenticate", { token: jwt.token }) //send the jwt
      .on("authenticated", () => {
        try {
          ReactGA.set({
            userId: jwt.user._id,
            userEmail: jwt.user.username,
            // any data that is relevant to the user session
            // that you would like to track with google analytics
          });
        } catch (err) {
          console.log(err);
        }
        //do other things
        // this.setState({screenName:jwt.token.username});
        //me.register((data) => this.setState(data)); //this callback is a learning !! don't forget this thing !
      })
      .on("unauthorized", (msg) => {
        console.log(`unauthorized: ${JSON.stringify(msg.data)}`);
      });
  });
}

class App extends Component {
  cleanSocket = () => {
    socket = null;
  }

  newTable = (props) => <Table {...props} socket={socket} />;

  depositPage = (props) => <Deposit {...props} socket={socket} />;

  newOnlinePlayerDashboard = (props) => (
    <OnlinePlayers {...props} socket={socket} />
  );

  newLobby = (props) => <Lobby {...props} isSocket={socket !== null} />;
  // getScreenName = () => {
  //   let name = prompt("enter your Name");
  //   this.setState({ screenName: name });
  // };

  newPoker = (props) => <Poker {...props} socket={socket} cleanSocket={this.cleanSocket} />

  handleChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  // handleSubmit = (e) => {
  //  e.preventDefault();
  //     this.setState({
  //     screenName: this.state.name
  //   })

  //    console.log(this.state);

  // }

  render() {
    return (
      <div className="App">
        <BrowserRouter history={history}>
          <PrivateRoute exact path="/">
            <Redirect to="/lobby"></Redirect>
          </PrivateRoute>
          {/* <Route
            exact path="/poker"
          >
            <Poker />
          </Route> */}

          <PrivateRoute exact path="/lobby" component={this.newPoker} />

          <PrivateRoute
            exact
            path="/table-5/:tableId" //3
            component={this.newTable}
            // render={(props) => (
            //   <Table {...props} socket={socket} />
            // )}
          />

          <AdminRoute
            path="/instamuse-admin"
            component={this.newOnlinePlayerDashboard}
          />

          {/* <Route exact path="/user/signup">
            <Signup />
          </Route> */}
          <Route exact path="/user/signin">
            <Signin />
          </Route>
          <PrivateRoute
            exact
            path="/deposit/:deposit_id"
            component={this.depositPage}
          />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
