import { API } from "../../backend";

export const signup = user => {
    console.log(API, typeof API)
    console.log(`${API}/signup`);
  return fetch(`${API}/user/signup`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(user)
  })
    .then(response => {
      return response.json();
    })
    .catch(err => console.log(err));
};

export const signin = user => {
  return fetch(`${API}/user/signin`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(user)
  })
    .then(response => {
      return response.json();
    })
    .catch(err => console.log(err));
};

export const verifyBitclout = data => {
  return fetch(`${API}/user/verify-bitclout`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(response => {
      return response.json();
    })
    .catch(err => console.log(err));
};

export const googleSignIn = idToken =>{
  return fetch(`${API}/user/googleSignIn`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      idToken
    })
  })
    .then(response => {
      return response.json();
    })
    .catch(err => console.log(err));
}

export const faceBookSignIn = ({accessToken, userID}) =>{
  return fetch(`${API}/user/logInWithFaceBook`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      accessToken, userID
    })
  })
    .then(response => {
      return response.json();
    })
    .catch(err => console.log(err));
}

export const authenticate = (data, next) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("jwt", JSON.stringify(data));
    next();
  }
};

export const getToken = (user) => {
  return fetch(`${API}/auth/get-token`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(user)
  })
}

export const signout = next => {
  if (typeof window !== "undefined") {
    localStorage.removeItem("jwt");
    next();
  }
};

export const isAutheticated = () => {
  if (typeof window == "undefined") {
    console.log("window undefined! ");
    return false;
  }
  if (localStorage.getItem("jwt")) {
    console.log("JWT TOKEN FOUND INSIDE AUTH")
    return JSON.parse(localStorage.getItem("jwt"));
  } else {
    console.log("JWT TOKEN NOT FOUND INSIDE ATH")
    return false;
  }
};
