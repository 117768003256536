import React, { Component } from "react";
import "./reset.css";
import "./style.css";
//import checkmark from "../../images/checkmark.png";
import chipImage from "../../images/chip.png";
import noImg from "../../images/no-img.png";
import gameTable from "../../images/table_transparent.png";
//import openSocket from "this.props.socket.io-client";
import Seat from "../Seat/Seat.component";
import { Redirect } from "react-router-dom";
import { PlayState, Tween } from "react-gsap";
import Call from "../Call/Call";

//import sounds:
import UIfx from "uifx";
import foldAudio from "./sounds/fold.wav";
import callAudio from "./sounds/call.wav";
import betAudio from "./sounds/bet.wav";
import raiseAudio from "./sounds/raise.wav";
import checkAudio from "./sounds/check.wav";
// import notificationAudio from "./sounds/notification.mp3"; // Removed this sound! it is irritating..
import { API } from "../../backend";
// import BugForm from "../BugForm/BugForm.component";
// import SendChipsForm from "../SendChips/SendChipsForm.component";
import InputSlider from "react-input-slider";

const callSound = new UIfx(callAudio);
const foldSound = new UIfx(foldAudio);
const betSound = new UIfx(betAudio);
const raiseSound = new UIfx(raiseAudio);
const checkSound = new UIfx(checkAudio);
// const notificationSound = new UIfx(notificationAudio); // Removed this sound! it is irritating..

// const this.props.socket = openSocket(API);

console.log("REACHED TABLE COMPONENT");

class Table extends Component {
  constructor(props) {
    console.log("TABLE CONSTRUCTOR CALLED");
    super(props);
    this._isMounted = true;
    this.uid = null;
    this.username = JSON.parse(localStorage.getItem("jwt")).user.username;
    this.seatHelper = null;
    this.mySeat = null;
    this.tableId = this.props.match.params.tableId;
    this.moveMoneyAnimation = [
      { y: "60", x: "190", opacity: 0 },
      { y: "60", x: "-190", opacity: 0 },
      { y: "-160", x: "-150", opacity: 0 },
      { y: "-160", x: "30", opacity: 0 },
      { y: "-100", x: "150", opacity: 0 },
    ];
    this.messageBox = React.createRef();
    this.state = {
      table: {
        seats: [],
        pot: [{}],
        board: ["", "", "", "", ""],
      },
      myCards: ["", ""],
      mySeat: null,
      screenName: this.username,
      totalChips: 0,
      betAmount: 0,
      showingChipsModal: false,
      actionState: "",
      dealerSeat: null,
      sittingOnTable: false,
      sittingIn: false,
      messages: [],
      tableId: this.props.match.params.tableId,
      redirect: false,
      positions: [0, 1, 2, 3, 4],
      buyInModalVisible: false,
      tableDataLoaded: false,
      backToLobby: false,
      error: null,
      playerInfoDialogVisible: false,
      playerToShow: {},
      blockPlayer: false,
      showLeaderBoard: false,
      leaderBoardData: [],
      enableLocalVideo: false,
      showWinnerText: null,
      winnerSeat: null,
      // amIhost:false
    };
  }

  fetchTableData = () => {
    const tableId = this.tableId;
    console.log("tableId: ", tableId);
    fetch(`${API}/table-data/${tableId}`)
      .then((response) => response.json())
      .then((data) => {
        console.log("TABLE DETAILS FECHED!!");
        // this.state.table = data.table;
        // this.state.buyInAmount = data.table.minBuyIn;
        // this.state.betAmount = data.table.bigBlind;
        this.setState({
          table: data.table,
          buyInAmount: data.table.minBuyIn,
          betAmount: data.table.bigBlind,
        });
        setTimeout(() => {
          this.setState({ tableDataLoaded: true });
        }, 1200);
      })
      .catch((err) => console.log(err));
  };

  fetchLeaderBoardData = () => {
    fetch(`${API}/users/leaderboard`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ leaderBoardData: data });
      })
      .catch((err) => {
        console.log("error loading leader board data");
      });
  };

  checkRedirect = () => {
    if (this.props.socket === null) {
      this.setState({ redirect: true });
    }
  };
  //this.props.socket io functions! for io.on events
  setMyCards = (cards) => {
    this.setState({ myCards: cards });
  };

  setTableData = (data) => {
    // if(data.activeSeat === this.state.mySeat)
    //   notificationSound.play(); // Removed this sound! it is irritating..
    this.setState({ table: data });
    console.log(data);
    if (data.log.message.length > 1) {
      var str = data.log.message;
      let pos = str.lastIndexOf("[");
      if (pos > 0) str = str.slice(0, pos - 1);
      this.setState({
        messages: [...this.state.messages, str],
      });
      if(data.log.message.includes("wins")){
        let winnerSeatIndex = this.state.table.seats.findIndex((seat)=>seat.name === str.split(" ")[0].toLowerCase());
        this.showWinner(str, str.includes("with") ? 6 : 3, winnerSeatIndex);
      }
      // console.log("Finding Winner seat location:", this.state.table.seats);
      // console.log(str.split(" ")[0].toLowerCase())
      // console.log(this.state.table.seats.findIndex((seat)=>seat.name === str.split(" ")[0].toLowerCase()));
    }


    switch (data.log.action) {
      case "fold":
        console.log("play some sound!");
        // sounds.playFoldSound();
        foldSound.play();
        break;
      case "check":
        console.log("play some sound!");
        checkSound.play();
        // sounds.playCheckSound();
        break;
      case "call":
        console.log("play some sound!");
        callSound.play();

        break;
      case "bet":
        console.log("play some sound!");
        // sounds.playBetSound();
        betSound.play();
        break;
      case "raise":
        console.log("play some sound!");
        raiseSound.play();
        // sounds.playRaiseSound();
        break;
      default:
        console.log("no sound! no log");
    }
    this.scrollMessages();
  };

  //manipulating seat positons for having the player in center
  arrangeSeats = (seat) => {
    this.seatHelper = seat;
    let pos = this.state.positions;
    pos[3] = seat;
    pos[4] = this.nextSeat();
    pos[0] = this.nextSeat();
    pos[1] = this.nextSeat();
    pos[2] = this.nextSeat();

    this.setState({ positions: pos });

    console.log("POSITIONS: ", this.state.positions);
  };

  nextSeat = () => {
    if (this.seatHelper >= 4) {
      this.seatHelper = 0;
    } else {
      this.seatHelper++;
    }
    return this.seatHelper;
  };

  gameStopped = (data) => {
    this.setState({ table: data, actionState: "waiting" });
  };

  postSmallBlind = () => {
    this.setState({ actionState: "postSmallBlind" });
  };

  postBigBlind = () => {
    this.setState({ actionState: "postBigBlind" });
  };

  actBettedPot = () => {
    const proposedBet = this.state.table.biggestBet + this.state.table.bigBlind;
    let myChipsInPlay;
    try {
      // console.log(`TEST: \n
      // MYSEATno: ${this.state.mySeat} \n
      // SEATS: ${this.state.table.seats} \n
      // MySEAT: ${this.state.table.seats[this.state.mySeat]} \n
      // SELECTED: ${this.state.selectedSeat}
      // `);
      myChipsInPlay = this.state.table.seats[this.state.mySeat].chipsInPlay;
    } catch (err) {
      console.log(err);
      if (myChipsInPlay === undefined) {
        // reportIssue();
        // socket.emit("getMyChipsInPlay", (chipsInPlay)=>{
        //   myChipsInPlay = chipsInPlay;
        // })
        //once updated socket.on for getMySeatNo in backend, delete below code:
        alert(
          "Hey, the game ran into an issue and we have to reset game at your side. This bug will be reported to our developer team and they will fix this asap. sorry for inconvenience."
        );
        setTimeout(() => {
          window.location.reload();
        }, 6000);
      }
    } finally {
      this.setState({
        actionState: "actBettedPot",
        betAmount: myChipsInPlay < proposedBet ? myChipsInPlay : proposedBet,
      });
    }
  };

  actNotBettedPot = () => {
    this.setState({
      actionState: "actNotBettedPot",
      betAmount:
        this.state.table.seats[this.state.mySeat].chipsInPlay <
        this.state.table.bigBlind
          ? this.state.table.seats[this.state.mySeat].chipsInPlay
          : this.state.table.bigBlind,
    });
  };

  actOthersAllIn = () => {
    this.setState({ actionState: "actOthersAllIn" });
  };

  enterRoom = () => {
    const { tableId } = this.props.match.params;
    console.log("enterroom was called");
    setTimeout(() => {
      this.props.socket.emit("enterRoom", tableId, (data) => {
        if (data.error) {
          if (data.error === "you are in sitting in different room") {
            window.onbeforeunload = null;
            this.leaveTable(() => {
              this.props.socket.emit("leaveRoom");
              window.location.reload();
            });
          } else {
            alert(data.error);
            this.setState({ redirect: true });
          }
        }

        this.setState(data);
        if (data.arrangeSeats) this.arrangeSeats(data.selectedSeat);
        console.log("ATTEMPTED TO UPDATE THE STATE SCREENNAME");
      });
    }, 1000);
  };

  componentDidUpdate() {
    if (this.state.sittingIn) {
      window.onbeforeunload = () => {
        return "Are you sure you want to leave?";
      };
    } else {
      window.onbeforeunload = null;
    }
  }

  componentDidMount() {
    let me = this;
    if (this.props.socket === null) {
      window.location.reload();
    }
    try {
      this.props.socket.emit("leaveRoom");
    } catch (err) {
      console.log("ERROR!");
    }

    this.fetchTableData();

    this.fetchLeaderBoardData();

    this.checkRedirect();

    this.enterRoom();

    console.log("components mounted");

    // When the table data have changed
    this.props.socket.on("table-data", this.setTableData);

    //When the game has stopped
    this.props.socket.on("gameStopped", this.gameStopped);

    // When the player is asked to place the small blind
    this.props.socket.on("postSmallBlind", this.postSmallBlind);

    // When the player is asked to place the big blind
    this.props.socket.on("postBigBlind", this.postBigBlind);

    // When the player is dealt cards
    this.props.socket.on("dealingCards", this.setMyCards);

    // When the user is asked to act and the pot was betted
    this.props.socket.on("actBettedPot", this.actBettedPot);

    // When the user is asked to act and the pot was not betted
    this.props.socket.on("actNotBettedPot", this.actNotBettedPot);

    // When the user is asked to call an all in
    this.props.socket.on("actOthersAllIn", this.actOthersAllIn);

    //show buyInForm when chips get zero
    this.props.socket.on("buyIntoGame", () => {
      this.leaveTable(this.updateState);
      this.showBuyInModal(this.mySeat);
    });

    //when user is disconnected
    this.props.socket.on("disconnect", (reason) => {
      console.log(
        "YOU ARE DISCONNECTED. PLEASE CHECK YOUR INTERNET CONNECTION"
      );
      console.log(reason);
      if(this._isMounted){
        if (reason === "transport close" || reason === "io server disconnect")
          alert("Disconnected! there was some prblem with the server.");
        else
          alert("You are disconnected! please check your Internet Connection.");
      }

      me.setState({
        actionState: "",
      });
      //close the video connection too!
    });

    //TEMP CODE:
    // this.props.socket.on("initGame",()=>{
    //   me.setState({amIhost:true});
    // });

    this.props.socket.on("temp", (data) => {
      me.setState(data);
    });
  }

  // register = (callback) => {
  //   console.log("inside register!");
  //   // let data = {};
  //   if (this.props.screenName) {
  //     this.props.socket.emit("register",this.uid, username, function (response) {
  //       if (response.success) {
  //         callback({
  //           screenName: response.screenName,
  //           totalChips: response.totalChips,
  //           registerError: "",
  //         });
  //       } else if (response.message) {
  //         // data =
  //         callback({ registerError: response.message });
  //       }
  //     });
  //   }
  // };

  //MINIMUM BET AMOUNT
  minBetAmount = () => {
    if (
      this.state.mySeat === null ||
      typeof this.state.table.seats[this.state.mySeat] === "undefined" ||
      this.state.table.seats[this.state.mySeat] === null
    )
      return 0;
    // If the pot was raised
    if (this.state.actionState === "actBettedPot") {
      //let this one be the loose pointn
      var proposedBet = this.state.table.biggestBet + this.state.table.bigBlind;
      return this.state.table.seats[this.state.mySeat].chipsInPlay < proposedBet
        ? this.state.table.seats[this.state.mySeat].chipsInPlay
        : proposedBet;
    } else {
      return this.state.table.seats[this.state.mySeat].chipsInPlay <
        this.state.table.bigBlind
        ? this.state.table.seats[this.state.mySeat].chipsInPlay
        : this.state.table.bigBlind;
    }
  };

  maxBetAmount = () => {
    if (
      this.state.mySeat === null ||
      typeof this.state.table.seats[this.state.mySeat] === "undefined" ||
      this.state.table.seats[this.state.mySeat] === null
    )
      return 0;
    return this.state.actionState === "actBettedPot"
      ? this.state.table.seats[this.state.mySeat].chipsInPlay +
          this.state.table.seats[this.state.mySeat].bet
      : this.state.table.seats[this.state.mySeat].chipsInPlay;
  };

  callAmount = () => {
    if (
      this.state.mySeat === null ||
      typeof this.state.table.seats[this.state.mySeat] === "undefined" ||
      this.state.table.seats[this.state.mySeat] == null
    )
      return 0;
    //let this one be the loose point too

    var callAmount =
      this.state.table.biggestBet -
      this.state.table.seats[this.state.mySeat].bet;
    return callAmount > this.state.table.seats[this.state.mySeat].chipsInPlay
      ? this.state.table.seats[this.state.mySeat].chipsInPlay
      : callAmount;
  };

  showLeaveTableButton = () => {
    //there was root scope in this function
    return (
      this.state.sittingOnTable !== false &&
      (!this.state.sittingIn || this.state.actionState === "waiting")
    );
  };

  showPostSmallBlindButton = () => {
    return (
      this.state.actionState === "actNotBettedPot" ||
      this.state.actionState === "actBettedPot"
    );
  };

  showPostBigBlindButton = () => {
    return (
      this.state.actionState === "actNotBettedPot" ||
      this.state.actionState === "actBettedPot"
    );
  };

  showFoldButton = () => {
    return (
      this.state.actionState === "actNotBettedPot" ||
      this.state.actionState === "actBettedPot" ||
      this.state.actionState === "actOthersAllIn"
    );
  };

  showCheckButton = () => {
    return (
      this.state.actionState === "actNotBettedPot" ||
      (this.state.actionState === "actBettedPot" &&
        this.state.table.biggestBet ===
          this.state.table.seats[this.state.mySeat].bet)
    );
  };

  showCallButton = () => {
    return (
      this.state.actionState === "actOthersAllIn" ||
      (this.state.actionState === "actBettedPot" &&
        !(
          this.state.actionState === "actBettedPot" &&
          this.state.table.biggestBet ===
            this.state.table.seats[this.state.mySeat].bet
        ))
    );
  };

  showBetButton = () => {
    return (
      this.state.actionState === "actNotBettedPot" &&
      this.state.table.seats[this.state.mySeat].chipsInPlay &&
      this.state.table.biggestBet <
        this.state.table.seats[this.state.mySeat].chipsInPlay
    );
  };

  showRaiseButton = () => {
    return (
      this.state.actionState === "actBettedPot" &&
      this.state.table.seats[this.state.mySeat].chipsInPlay &&
      this.state.table.biggestBet <
        this.state.table.seats[this.state.mySeat].chipsInPlay
    );
  };

  showBetRange = () => {
    return (
      (this.state.actionState === "actNotBettedPot" ||
        this.state.actionState === "actBettedPot") &&
      this.state.table.seats[this.state.mySeat].chipsInPlay &&
      this.state.table.biggestBet <
        this.state.table.seats[this.state.mySeat].chipsInPlay
    );
  };

  showBetInput = () => {
    return (
      (this.state.actionState === "actNotBettedPot" ||
        this.state.actionState === "actBettedPot") &&
      this.state.table.seats[this.state.mySeat].chipsInPlay &&
      this.state.table.biggestBet <
        this.state.table.seats[this.state.mySeat].chipsInPlay
    );
  };

  showBuyInModal = (seat) => {
    this.setState({ buyInModalVisible: true, selectedSeat: seat });
    // selectedSeat = seat;
  };

  potText = () => {
    if (
      typeof this.state.table.pot !== "undefined" &&
      this.state.table.pot[0].amount
    ) {
      var potText = "Pot: " + this.state.table.pot[0].amount;

      var potCount = this.state.table.pot.length;
      if (potCount > 1) {
        for (var i = 1; i < potCount; i++) {
          potText += " - Sidepot: " + this.state.table.pot[i].amount;
        }
      }
      return potText;
    }
  };

  getCardClass = (seat, card) => {
    if (this.state.mySeat === seat) {
      return this.state.myCards[card];
    } else if (
      typeof this.state.table.seats !== "undefined" &&
      typeof this.state.table.seats[seat] !== "undefined" &&
      this.state.table.seats[seat] &&
      typeof this.state.table.seats[seat].cards !== "undefined" &&
      typeof this.state.table.seats[seat].cards[card] !== "undefined"
    ) {
      return "card-" + this.state.table.seats[seat].cards[card];
    } else {
      return "card-back";
    }
  };

  seatOccupied = (seat) => {
    //rootscope was used here too
    return (
      !this.state.sittingOnTable ||
      (this.state.table.seats !== "undefined" &&
        typeof this.state.table.seats[seat] !== "undefined" &&
        this.state.table.seats[seat] &&
        this.state.table.seats[seat].name)
    );
  };

  // Leaving the this.props.socket room
  leaveRoom = () => {
    this.props.socket.emit("leaveRoom");
    this.setState({ backToLobby: true });
  };

  // A request to sit on a specific seat on the table
  sitOnTheTable = (callback) => {
    let me = this;
    console.log("sitOnTheTable called");
    //rootParams was here
    // let data = {};
    let tableId = this.state.tableId;
    let selectedSeat = this.state.selectedSeat;
    console.log("inside sitOnTheTable");
    console.log(selectedSeat);
    console.log(this.state.buyInAmount);
    console.log(tableId);
    this.props.socket.emit(
      "sitOnTheTable",
      {
        seat: selectedSeat,
        tableId: this.state.tableId,
        chips: this.state.buyInAmount,
      },
      function (response) {
        console.log(response);
        console.log("SELECTED SEAT: ", selectedSeat);
        if (response.success) {
          // data =
          callback({
            buyInModalVisible: false,
            //rootScope:
            sittingOnTable: tableId,
            //rootScope
            sittingIn: true,
            buyInError: null,
            mySeat: selectedSeat,
            actionState: "waiting",
          });
          //localStorage.setItem("tableId",me.tableId);
          me.mySeat = selectedSeat;
          // $scope.buyInModalVisible = false;
          // $rootScope.sittingOnTable = $routeParams.tableId;
          // $rootScope.sittingIn = true;
          // $scope.buyInError = null;
          // $scope.mySeat = selectedSeat;
          // $scope.actionState = 'waiting';
          // $scope.$digest();
        } else {
          if (response.error) {
            // $scope.buyInError = response.error;
            // data =
            callback({ buyInError: response.error });
            // $scope.$digest();
          }
        }
      }
    );
  };

  updateState = (data) => {
    this.setState(data);
  };

  // Sit in the game
  sitIn = (callback) => {
    console.log("sitin called");
    // let data = {};
    this.props.socket.emit("sitIn", function (response) {
      if (response.success) {
        // data =
        callback({
          sittingIn: true,
        });
        // $rootScope.sittingIn = true;
      }
    });
    // setTimeout(() => {
    //   this.setState(data);
    // }, 1000);
  };

  // Leave the table (not the room)
  leaveTable = (callback) => {
    this.props.socket.emit("leaveTable", function (response) {
      if (response.success) {
        //rootScope was here
        callback({
          sittingOnTable: false,
          totalChips: response.totalChips,
          sittingIn: false,
          actionState: "",
          enableLocalVideo: false,
        });
        window.onbeforeunload = null;
        // $rootScope.sittingOnTable = null;
        // $rootScope.totalChips = response.totalChips;
        // $rootScope.sittingIn = false;
        // $scope.actionState = '';
        // $rootScope.$digest();
        // $scope.$digest();
      }
    });
  };

  // Post a blind (or not)
  postBlind = (posted, callback) => {
    this.props.socket.emit("postBlind", posted, function (response) {
      console.log(response);
      if (response.success && !posted) {
        //rootscope
        callback({ sittingIn: false });
        // $rootScope.sittingIn = false;
      } else {
        // sounds.playBetSound();
        betSound.play();
        console.log("playBetSound");
      }
    });
  };

  check = (callback) => {
    this.props.socket.emit("check", function (response) {
      if (response.success) {
        console.log("playCheckSound");
        checkSound.play();
        // sounds.playCheckSound();
        callback({ actionState: "" });
        // $scope.actionState = '';
        // $scope.$digest();
      }
    });
  };

  fold = (callback) => {
    this.props.socket.emit("fold", function (response) {
      if (response.success) {
        // sounds.playFoldSound();
        foldSound.play();
        console.log("playFoldSound");
        callback({ actionState: "" });
        // $scope.actionState = '';
        // $scope.$digest();
      }
    });
  };

  call = (callback) => {
    this.props.socket.emit("call", function (response) {
      if (response.success) {
        console.log("callSound");
        callSound.play();
        // $scope.actionState = '';
        callback({ actionState: "" });
        // $scope.$digest();
      }
    });
  };

  bet = (callback) => {
    this.props.socket.emit("bet", this.state.betAmount, function (response) {
      if (response.success) {
        // sounds.playBetSound();
        betSound.play();
        console.log("playBetSound");
        // $scope.actionState = '';
        callback({ actionState: "" });
        // $scope.$digest();
      }
    });
  };

  raise = (callback) => {
    this.props.socket.emit("raise", this.state.betAmount, function (response) {
      if (response.success) {
        // sounds.playRaiseSound();
        raiseSound.play();
        console.log("playRaiseSound");
        // $scope.actionState = '';
        callback({ actionState: "" });
        // $scope.$digest();
      }
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderDivs() {
    let uiItems = [];
    let messages = this.state.messages;
    messages.forEach((message) => {
      uiItems.push(<div className="message">{message}</div>);
    });
    return uiItems;
  }

  scrollMessages = () => {
    if (this.messageBox.current !== null)
      this.messageBox.current.scrollTop = this.messageBox.current.scrollHeight;
  };

  //temp solution for avoiding crash
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    } else if (this.state.backToLobby) {
      return <Redirect to="/lobby" />;
    }
  };

  //show dealer badge
  renderDealerBadge = () => {
    if (this.state.table.dealerSeat !== null) {
      let dl = this.state.positions.findIndex(
        (seat) => seat === this.state.table.dealerSeat
      );
      console.log("DEALER: ", dl);
      return <div className={`badge dl player${dl + 1}-badge`}>DL</div>;
    }
  };

  //rounding a number
  round = (num, precision) => {
    num = parseFloat(num);
    if (!precision) return num;
    return Math.round(num / precision) * precision + this.minBetAmount();
  };

  betRange = () => {
    return Number(this.maxBetAmount()) - Number(this.minBetAmount());
  };

  copyToClipboard = () => {
    try {
      const el = document.createElement("textarea");
      el.value = window.location.href;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      alert("link copied to clipboard, share it with friends");
    } catch (err) {
      console.log(err);
      alert(
        `Link not copied. please share this tableId: ${this.state.tableId}`
      );
    }
  };

  showInfo = (player) => {
    this.setState({ playerInfoDialogVisible: true, playerToShow: player });
    console.log(player);
  };

  blockPlayerVideo = () => {
    this.setState({ blockPlayer: this.state.playerToShow });
  };

  playerInfoDialog = () => {
    return (
      <div className="modal-wrap">
        <div className="modal">
          <h2>Player Info</h2>
          <p>Name: {this.state.playerToShow.originalName}</p>
          <p>Chips in Play: {this.state.playerToShow.chipsInPlay}</p>
          <div>
            {/* <button onClick={()=>{this.reportPlayer}}></button> */}
            <button className="red" onClick={this.blockPlayerVideo}>
              Block Video
            </button>
          </div>
        </div>
        <div
          className={this.state.playerInfoDialogVisible ? "curtain" : "hide"}
          onClick={() => {
            this.setState({ playerInfoDialogVisible: false });
          }}
        ></div>
      </div>
    );
  };

  renderLeaderBoardData = () => {
    let uiElements = [];
    this.state.leaderBoardData.forEach((user) => {
      uiElements.push(
        <li className="leader-item">
          <div className="profile">
            <img src="images/player1.jpg" alt="" /> <span>{user.name}</span>
          </div>
          <div className="chip-amount">{user.pokerLive.total_chips}</div>
        </li>
      );
    });
    return uiElements;
  };

  toggleLeaderBoard = () => {
    this.setState({ showLeaderBoard: !this.state.showLeaderBoard });
  };

  showWinner = (text, duration, winnerSeat) => {
    this.setState({showWinnerText: text, winnerSeat});
    setTimeout(() => {
      this.setState({showWinnerText: null, winnerSeat: null})
    }, duration * 1000)
  }

  render() {
    return (
      <div>
        {this.renderRedirect()}
        <div ref={this.messageBox} className="message-container">
          {this.renderDivs()}
        </div>
        {/* player Info Dialog:  */}
        {this.state.playerInfoDialogVisible && this.playerInfoDialog()}
        {/* buy in form */}
        <div className={this.state.buyInModalVisible ? "modal-wrap" : "hide"}>
          {/* new code:  */}
          <div id="poker-modal" class="poker-modal show">
            <div class="modal-dialog">
              <button
                class="modal-close"
                onClick={() => {
                  this.setState({ buyInModalVisible: false });
                }}
              >
                <ion-icon name="close-outline"></ion-icon>
              </button>
              <div class="modal-content">
                <div class="modal-title">
                  <h1 class="modal-heading">{this.state.table.name}</h1>
                  <p class="sub-heading">
                    <ion-icon name="globe-outline"></ion-icon> Public room
                  </p>
                </div>
                <div class="modal-body">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.sitOnTheTable(this.updateState);
                      this.arrangeSeats(this.state.selectedSeat);
                    }}
                  >
                    <div class="range-price">
                      <div id="minPrice">
                        <div class="price">{this.state.table.minBuyIn}</div>
                        <span>Min buy-in </span>
                      </div>
                      <div class="inputPrice">
                        <span class="inputSign">$</span>
                        <input
                          type="number"
                          id="fillInput"
                          value={this.state.buyInAmount}
                          onChange={(e) => {
                            this.setState({ buyInAmount: e.target.value });
                          }}
                        />
                      </div>
                      <div id="maxPrice">
                        <div class="price">{this.state.table.maxBuyIn}</div>
                        <span>Max buy-in </span>
                      </div>
                    </div>
                    <div class="slidecontainer">
                       
                      <input
                        type="range"
                        min={this.state.table.minBuyIn}
                        max={this.state.table.maxBuyIn}
                        onChange={(e) => {
                          this.setState({ buyInAmount: e.target.value });
                        }}
                        value={this.state.buyInAmount}
                        class="slider"
                        id="myRange"
                      />
                    </div>
                    <button type="submit" class="btn is-round btn-warning">
                      Sit In
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="modal">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.sitOnTheTable(this.updateState);
                this.arrangeSeats(this.state.selectedSeat);
              }}
            >
              <h4>{this.state.table.name}</h4>
              <span className="table-requirements">
                Max buy-in {this.state.table.maxBuyIn}
              </span>
              <span className="table-requirements">
                Min buy-in {this.state.table.minBuyIn}
              </span>
              <span className="table-requirements">
                You have a total of {this.state.totalChips} chips
              </span>
              <input
                className="input-left"
                type="number"
                value={this.state.buyInAmount}
                onChange={(e) => {
                  this.setState({ buyInAmount: e.target.value });
                }}
                required
              />
              <button>Sit IN</button>
            </form>
          </div> */}
          <div
            className={this.state.buyInModalVisible ? "curtain" : "hide"}
            onClick={() => {
              this.setState({ buyInModalVisible: false });
            }}
          ></div>
        </div>

        <Call
          playerBlocked={() =>
            this.setState({
              blockPlayer: false,
              playerInfoDialogVisible: false,
            })
          }
          blockPlayer={this.state.blockPlayer}
          sittingIn={this.state.sittingIn}
          positions={this.state.positions}
          streamId={this.state.screenName}
          players={this.state.table.seats}
          channel={this.state.sittingOnTable}
          enableLocalVideo={this.state.enableLocalVideo}
        />

        {this.state.channel !== "" &&
          this.state.sittingIn &&
          this.state.enableLocalVideo === false && (
            <button
              className="btn"
              style={{
                position: "fixed",
                margin: "0 auto",
                top: "10%",
                left: "35%",
                zIndex: 20,
                width: "25%",
              }}
              onClick={() => this.setState({ enableLocalVideo: true })}
            >
              Join With Video and Audio
            </button>
          )}

        <div className={!this.state.tableDataLoaded ? "loader" : "hide"}>
          <img src={chipImage} alt="" />
          <figcaption>Loading Table Data</figcaption>
        </div>
        <div class="poker-header">
          <div class="header-left">
            <a
              className={!this.state.sittingOnTable ? "button btn-red" : "hide"}
              onClick={this.leaveRoom}
            >
              <ion-icon name="exit"></ion-icon> Leave room{" "}
            </a>
            <div class="header-user">
              <img class="player-profile" src={noImg} alt="img" />
              <span>{this.state.screenName}</span>
            </div>
          </div>
          <div class="roomdID">Room ID: {this.state.tableId}</div>
          <div class="header-right">
            <a onClick={this.copyToClipboard} class="button btn-ghost">
              <ion-icon name="person-add"></ion-icon> Invite Friend
            </a>
            <div class="header-amount">
              <img class="amount-icon" src={chipImage} alt="chips" />
              <span>${this.state.totalChips}</span>
              <a class="btn-buy">
                <ion-icon name="add-outline"></ion-icon>
              </a>
            </div>
          </div>
        </div>
        <div className="poker">
          <div className="game-table">
          {
            this.state.showWinnerText ? 
            <div className={"wins-message"} style={{position: 'fixed', top: "20%", textAlign: "center", zIndex: 500}} >
              <h4 style={{fontSize: "1.5em"}}>{this.state.showWinnerText.split(" ").splice(0, 5).toString().replaceAll(",", " ").replace(")", "").replace("(", "of $").toUpperCase()}</h4>
              <h6 style={{marginTop: "-2.7em"}}>{this.state.showWinnerText.split(" ").splice(5, 10).toString().replaceAll(",", " ").toUpperCase() }</h6>
            </div> : 
            null
          }
            <img
              class="poker-table fluid"
              src={gameTable}
              alt="The Clout Poker"
            />
          </div>
          {/* <button 
            className={this.state.amIhost?"start-game":"hide"}
            onClick={()=>{
              this.props.socket.emit("initializeGame",this.state.tableId)
              this.setState({amIhost:false})
            }}
          >
            Initialize Game ?
          </button> */}
          <Seat
            pos="player1"
            seatIndex={this.state.positions[0]}
            mySeat={this.state.mySeat}
            myCards={this.state.myCards}
            player={this.state.table.seats[this.state.positions[0]]}
            activeSeat={this.state.table.activeSeat}
            tableId={this.state.tableId}
            sittingIn={this.state.sittingIn}
            showInfo={this.showInfo}
            moveMoneyAnimation={this.moveMoneyAnimation[0]}
            sitIn={async () => {
              console.log("reached mySitIn");
              this.showBuyInModal(0);
              // await this.setState({ selectedSeat: 0 });
              // this.sitOnTheTable(this.updateState);
            }}
            winnerSeat={this.state.winnerSeat}
          />
          <Seat
            pos="player2"
            seatIndex={this.state.positions[1]}
            mySeat={this.state.mySeat}
            myCards={this.state.myCards}
            player={this.state.table.seats[this.state.positions[1]]}
            activeSeat={this.state.table.activeSeat}
            tableId={this.state.tableId}
            sittingIn={this.state.sittingIn}
            showInfo={this.showInfo}
            moveMoneyAnimation={this.moveMoneyAnimation[1]}
            sitIn={async () => {
              console.log("reached mySitIn");
              this.showBuyInModal(1);
              // await this.setState({ selectedSeat: 1 });
              // this.sitOnTheTable(this.updateState);
            }}
            winnerSeat={this.state.winnerSeat}
          />
          <Seat
            pos="player3"
            seatIndex={this.state.positions[2]}
            mySeat={this.state.mySeat}
            myCards={this.state.myCards}
            player={this.state.table.seats[this.state.positions[2]]}
            activeSeat={this.state.table.activeSeat}
            tableId={this.state.tableId}
            sittingIn={this.state.sittingIn}
            showInfo={this.showInfo}
            moveMoneyAnimation={this.moveMoneyAnimation[2]}
            sitIn={async () => {
              console.log("reached mySitIn");
              this.showBuyInModal(2);
              // await this.setState({ selectedSeat: 2 });
              // this.sitOnTheTable(this.updateState);
            }}
            winnerSeat={this.state.winnerSeat}
          />
          <Seat
            pos="player4"
            seatIndex={this.state.positions[3]}
            mySeat={this.state.mySeat}
            myCards={this.state.myCards}
            player={this.state.table.seats[this.state.positions[3]]}
            activeSeat={this.state.table.activeSeat}
            tableId={this.state.tableId}
            sittingIn={this.state.sittingIn}
            showInfo={this.showInfo}
            moveMoneyAnimation={this.moveMoneyAnimation[3]}
            sitIn={async () => {
              console.log("reached mySitIn");
              this.showBuyInModal(3);
              // await this.setState({ selectedSeat: 3 });
              // this.sitOnTheTable(this.updateState);
            }}
            winnerSeat={this.state.winnerSeat}
          />
          <Seat
            pos="player5"
            seatIndex={this.state.positions[4]}
            mySeat={this.state.mySeat}
            myCards={this.state.myCards}
            player={this.state.table.seats[this.state.positions[4]]}
            activeSeat={this.state.table.activeSeat}
            tableId={this.state.tableId}
            sittingIn={this.state.sittingIn}
            showInfo={this.showInfo}
            moveMoneyAnimation={this.moveMoneyAnimation[4]}
            sitIn={async () => {
              console.log("reached mySitIn");
              this.showBuyInModal(4);
              // await this.setState({ selectedSeat: 3 });
              // this.sitOnTheTable(this.updateState);
            }}
            winnerSeat={this.state.winnerSeat}
          />

          <div
            className={
              this.state.table.pot[0].amount === 0
                ? "hide"
                : "table-amount-container"
            }
          >
            <div className="table-amount">
              <img src={chipImage} alt="Chip" />{" "}
              <span>${this.state.table.pot[0].amount}</span>
            </div>
          </div>
          <div className="table-cards-container">
            <div className="table-cards">
              <Tween
                playState={
                  this.state.table.board[0] !== ""
                    ? PlayState.play
                    : PlayState.stop
                }
                from={{ opacity: 0, top: "-=1000" }}
                stagger={0.1}
                duration={0.5}
              >
                <div
                  className={`main-card card ${
                    this.state.table.board[0] === ""
                      ? "card-back"
                      : `card-${this.state.table.board[0]}`
                  }`}
                >
                  {/* <img alt="K" /> */}
                </div>
                <div
                  className={`main-card card ${
                    this.state.table.board[1] === ""
                      ? "card-back"
                      : `card-${this.state.table.board[1]}`
                  }`}
                >
                  {/* <img src="images/card4.png" alt="A" /> */}
                </div>
                <div
                  className={`main-card card ${
                    this.state.table.board[2] === ""
                      ? "card-back"
                      : `card-${this.state.table.board[2]}`
                  }`}
                >
                  {/* <img src="images/card5.png" alt="J" /> */}
                </div>
                <div
                  className={`main-card card ${
                    this.state.table.board[3] === ""
                      ? "card-back"
                      : `card-${this.state.table.board[3]}`
                  }`}
                >
                  {/* <img src="images/card.png" alt="Q" />
                <img src="images/card6.png" alt="Q" className=" /> */}
                </div>
                <div
                  className={`main-card card ${
                    this.state.table.board[4] === ""
                      ? "card-back"
                      : `card-${this.state.table.board[4]}`
                  }`}
                >
                  {/* <img src="images/card.png" alt="A" />
                <img src="images/card7.png" alt="Q" className=" /> */}
                </div>
              </Tween>
            </div>
          </div>
          {/* <div className="badge bb player1-badge">BB</div>
          <div className="badge dl player4-badge">DL</div>
          <div className="badge sb player5-badge">SB</div> */}

          {/* <a href="#" class="button btn-red"><ion-icon name="exit"></ion-icon> Leave room </a>
            <div class="header-user">
                <img class="player-profile" src="images/no-img.png" />
                <span>Tuvshuu1211</span>
            </div> */}
          {/* <button
            className={!this.state.sittingOnTable ? "leave-room" : "hide"}
            onClick={this.leaveRoom}
          >
            <strong>{`<-Back To Lobby`}</strong>
          </button> */}
          {/* <button className="reportBugButton">Report a bug</button> */}
          {/* <div className="reportBugButton">
            <BugForm tableState={this.state} />
            {this.username === this.state.table.host && (
              <SendChipsForm
                players={this.state.table.seats}
                socket={this.props.socket}
              />
            )}
          </div> */}
          {/* <div className="showId">
            Table ID: {this.state.tableId}
            <button className="inviteLink" onClick={this.copyToClipboard}>
              Invite Friends
            </button>
          </div> */}
          {this.renderDealerBadge()}
          <div
            className={
              this.state.table.activeSeat === this.state.mySeat ||
              this.showLeaveTableButton() ||
              (this.state.sittingOnTable !== false && !this.state.sittingIn)
                ? "buttons"
                : "hide"
            }
          >
            <div className="buttons-left">
              {/* tl.from(".buttons a", .5, {bottom:"-=1000", stagger:0.1, ease: "back.out(1.1)"}) */}
              <Tween
                playState={
                  this.showFoldButton() ||
                  this.showCheckButton() ||
                  this.state.actionState === "postSmallBlind" ||
                  this.state.actionState === "postBigBlind" ||
                  this.showLeaveTableButton() ||
                  (this.state.sittingOnTable !== false && !this.state.sittingIn)
                    ? PlayState.play
                    : PlayState.stop
                }
                from={{ bottom: "-=1000" }}
                stagger={0.1}
                ease={"back.out(1.1)"}
              >
                <button
                  className={this.showFoldButton() ? "button-fold" : "hide"}
                  onClick={() => this.fold(this.updateState)}
                >
                  FOLD
                </button>
                <button
                  className={this.showCheckButton() ? "button-check" : "hide"}
                  onClick={() => this.check(this.updateState)}
                >
                  CHECK
                </button>
                <button
                  className={
                    this.state.actionState === "postSmallBlind" ||
                    this.state.actionState === "postBigBlind"
                      ? "button-blind"
                      : "hide"
                  }
                  onClick={() => {
                    this.postBlind(true, this.updateState);
                  }}
                >
                  BLIND (
                  {this.state.actionState === "postSmallBlind"
                    ? this.state.table.smallBlind
                    : this.state.table.bigBlind}
                  )
                </button>
                <button
                  className={
                    this.state.actionState === "postSmallBlind" ||
                    this.state.actionState === "postBigBlind"
                      ? "button-sitout"
                      : "hide"
                  }
                  onClick={() => {
                    this.postBlind(false, this.updateState);
                  }}
                >
                  SIT OUT
                </button>
                <button
                  className={
                    this.showLeaveTableButton() ? "button-leaveTable" : "hide"
                  }
                  onClick={() => {
                    this.leaveTable(this.updateState);
                  }}
                >
                  LEAVE TABLE
                </button>
                <button
                  className={
                    this.state.sittingOnTable !== false && !this.state.sittingIn
                      ? "button-sitin"
                      : "hide"
                  }
                  onClick={() => {
                    this.sitIn(this.updateState);
                  }}
                >
                  SIT IN
                </button>
              </Tween>
            </div>
            <div className="buttons-right">
              <Tween
                playState={
                  this.showCallButton() ||
                  this.showBetButton() ||
                  this.showRaiseButton()
                    ? PlayState.play
                    : PlayState.stop
                }
                from={{ bottom: "-=1000" }}
                stagger={0.1}
                ease={"back.out(1.1)"}
              >
                <button
                  className={this.showCallButton() ? "button-call" : "hide"}
                  onClick={() => this.call(this.updateState)}
                >
                  CALL ({this.callAmount()})
                </button>
                <button
                  className={this.showBetButton() ? "button-bet" : "hide"}
                  onClick={() => this.bet(this.updateState)}
                >
                  BET ({this.state.betAmount})
                </button>
                <button
                  className={this.showRaiseButton() ? "button-raise" : "hide"}
                  onClick={() => this.raise(this.updateState)}
                >
                  RAISE TO ({this.state.betAmount})
                </button>
              </Tween>
            </div>
          </div>
          <div
            className={
              this.state.table.activeSeat === this.state.mySeat &&
              (this.showBetButton() || this.showRaiseButton())
                ? "bet"
                : "hide"
            }
          >
            <div className="col-left">
              <button
                className="bet-allin"
                onClick={() =>
                  this.setState({ betAmount: this.maxBetAmount() })
                }
              >
                All In
              </button>
              <button
                className="bet-12m" //if not using in css change this classname
                onClick={() => {
                  let bet50 = this.round(Number(this.betRange()) / 2, 10);
                  console.log("bet50: ", bet50);
                  this.setState({ betAmount: bet50 });
                }}
              >
                {this.round(Number(this.betRange()) / 2, 10)}
              </button>
              <button
                className="bet-6m"
                onClick={() => {
                  let bet25 = this.round(Number(this.betRange()) / 4, 10);
                  console.log("bet25: ", bet25);
                  this.setState({ betAmount: bet25 });
                }}
              >
                {this.round(Number(this.betRange()) / 4, 10)}
              </button>
              <button
                className="bet-plus"
                onClick={() => {
                  if (this.maxBetAmount() >= this.state.betAmount)
                    this.setState({
                      betAmount: Number(this.state.betAmount) + this.state.table.bigBlind,
                    });
                }}
              >
                +
              </button>
              <input
                type="text"
                value={this.state.betAmount}
                //onChange={(e) => this.setState({ betAmount: e.target.value })}
                className="bet-value"
              />
              <button
                className="bet-minus"
                onClick={() => {
                  if (this.minBetAmount() + this.state.table.bigBlind <= this.state.betAmount)
                    this.setState({ betAmount: this.state.betAmount - this.state.table.bigBlind });
                }}
              >
                -
              </button>
            </div>
            <div className="col-right">
              <InputSlider
                axis='y'
                styles={{
                  track: {
                    backgroundColor: "white",
                    height: '100%'
                  },
                  active: {
                    backgroundColor: "#10cc4e",
                  },
                  thumb: {
                    width: 25,
                    height: 25,
                    color: "#10cc4e"
                  },
                  disabled: {
                    opacity: 0.5,
                  },
                }}
                yreverse={true}
                y={this.state.betAmount}
                ymax={this.maxBetAmount()}
                ymin={this.minBetAmount()}
                ystep={this.state.table.bigBlind}
                onChange={({y}) => this.setState({ betAmount: y })}
              />
              {/* <div className="progress-bar">
                <input
                  type="range"
                  orient="vertical"
                  step={10}
                  value={this.state.betAmount}
                  min={this.minBetAmount()}
                  max={this.maxBetAmount()}
                  onChange={(e) => this.setState({ betAmount: e.target.value })}
                  className="progress"
                />
                <span className="progress"></span>
              </div> */}
            </div>
          </div>
        </div>
        <div
          className={
            this.state.showLeaderBoard
              ? "leader-board"
              : "leader-board is-active"
          }
        >
          <div class="section-header">
            <h2>
              {" "}
              <span>Leader</span> Board
            </h2>
            <div className="toggle-button" onClick={this.toggleLeaderBoard}>
              {this.state.showLeaderBoard ? "_" : "+"}
            </div>
          </div>
          <div className="leaders-list">
            <ul>
              {this.state.leaderBoardData.length
                ? this.renderLeaderBoardData()
                : "No data available"}
            </ul>
            {/* <ul>               
                <li class="leader-item">
                  <div class="profile">
                    <img src="images/player1.jpg" alt="" />{" "}
                    <span>John doe</span>
                  </div>
                  <div class="chip-amount">$ 123.456</div>
                </li>
                <li class="leader-item">
                  <div class="profile">
                    <img src="images/player1.jpg" alt="" />{" "}
                    <span>John doe</span>
                  </div>
                  <div class="chip-amount">$ 123.456</div>
                </li>
                <li class="leader-item">
                  <div class="profile">
                    <img src="images/player1.jpg" alt="" />{" "}
                    <span>John doe</span>
                  </div>
                  <div class="chip-amount">$ 123.456</div>
                </li>
                <li class="leader-item">
                  <div class="profile">
                    <img src="images/player1.jpg" alt="" />{" "}
                    <span>John doe</span>
                  </div>
                  <div class="chip-amount">$ 123.456</div>
                </li>
              </ul> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Table;
