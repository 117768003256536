/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Redirect } from "react-router-dom";
import "../../App.css";
import "../../enk.css";
import cx from "classnames";
import styles from "./Poker.module.css";
import { API } from "../../backend";
import { signout, isAutheticated } from "../../auth/helper";
import chipAmountImg from "../../images/chip-amount.png";
import plusButtonImg from "../../images/plus-button.png";
import { Component } from "react";
import cardImg from "../../images/card.png";
import tableImg from "../../images/table.png";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

export default class Poker extends Component {
  state = {
    showJoinRoomForm: false,
    showCreateRoomForm: false,
    tableName: "",
    smallBlind: 10,
    isPrivate: true,
    password: "",
    total_chips: 0,
    username: "",
    name: "",
    tableId: null,
    cardClass: "card-back",
    success: false,
    lobbyTables: null,
    showPrivateTables: false,
    hoveredTable: null,
    withdrawalFormOpen: false,
    withdrawal_amount: 0,
    withdrawalLoading: false,
    withdrawalSuccess: false,
    depositFormOpen: false,
    deposit_amount_in_bitclout: 0,
    depositLoading: false,
    depositSuccess: false,
    withdrawalError: null
  };

  componentDidMount() {
    if(!this.props.socket){
      window.location.reload();
    }
    this.fetchLobbyData();
    this.getUserData();
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handlePrivate = (event) => {
    console.log("CHECKED: ", event.target.checked);
    this.setState({ isPrivate: event.target.checked });
  };

  handleJoinRoom = (event) => {
    event.preventDefault();
    fetch(`${API}/table/join/${this.state.tableId}/${this.state.password}`)
      .then((response) => response.json())
      .then((data) => {
        console.log("JOIN THIS ROOM: ", data);
        if (data.success) {
          this.props.history.push(`/table-5/${data.tableId}`);
        }
        // this.setState({ success: data.success, tableId: data.tableId });
      });
  };

  handleCreateRoom = (event) => {
    event.preventDefault();
    const auth = isAutheticated();
    console.log(auth);
    const data = {
      username: this.state.username, //send username for setting host
      name: this.state.tableName,
      smallBlind: this.state.smallBlind,
      isPrivate: this.state.isPrivate,
      password: this.state.password,
    };

    fetch(`${API}/table/create`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("ROOM CREATED", data);
        if (data.success) {
          this.props.history.push(`/table-5/${data.tableId}`);
        }
        // this.setState(data);
      })
      .catch((err) => console.log(err));
    this.setState({ showCreateForm: false });
  };

  joinRoomForm = () => {
    return (
      <div className={this.state.showJoinRoomForm ? "modal-wrap" : "hide"}>
        {/* <div id="applixir_vanishing_div" hidden>
          <iframe id="applixir_parent" allow="autoplay"></iframe>
        </div> */}
        <div id="join-room-form" className="join-room-form">
          <h2>Join Table</h2>
          <form action="#" onSubmit={this.handleJoinRoom}>
            <input
              required
              type="text"
              value={this.state.tableId}
              onChange={this.handleChange("tableId")}
              placeholder="Table ID"
            />
            <input
              type="text"
              placeholder="password"
              onChange={this.handleChange("password")}
              className={this.state.isPrivate ? "password-field" : "hide"}
            />

            <button
              type="submit"
              style={{
                zIndex: 100,
                color: "orange",
              }}
              className="create-room-modal-button btn1"
            >
              Join Table
            </button>
          </form>
        </div>
        <div
          className="curtain"
          onClick={() =>
            this.setState({
              showJoinRoomForm: false,
              isPrivate: true,
              tableId: "",
            })
          }
        ></div>
      </div>
    );
  };

  createRoomForm = () => {
    return (
      <div className={this.state.showCreateRoomForm ? "modal-wrap" : "hide"}>
        <div id="create-room-form" className="create-room-form">
          <h2>Create Table</h2>
          <form action="#" onSubmit={this.handleCreateRoom}>
            <input
              required
              type="text"
              onChange={this.handleChange("tableName")}
              placeholder="Room name"
            />
            <input
              required
              type="range"
              min={5}
              max={40}
              step={5}
              onChange={this.handleChange("smallBlind")}
              className="js-range-slider"
              name="my_range"
              value={this.state.smallBlind}
            />
            <div>
              {this.state.smallBlind}/{this.state.smallBlind * 2}
            </div>
            <br />

            {/* <span className="mySp">pick one card style: </span>
            <div></div> */}
            {/* <div onClick={()=>setCardClass("card-back1")} className="card main-card custom-card-back card-back1"></div>
            <div onClick={()=>setCardClass("card-back2")} className="card main-card custom-card-back card-back2"></div>
            <div onClick={()=>setCardClass("card-back3")} className="card main-card custom-card-back card-back3"></div>
            <div onClick={()=>setCardClass("card-back4")} className="card main-card custom-card-back card-back4"></div> */}
            {/* <input
              type="checkbox"
              onChange={handlePrivate}
              className="mybox"
            /> */}
            <input
              type="text"
              placeholder="password"
              onChange={this.handleChange("password")}
              className={this.state.isPrivate ? "password-field" : "hide"}
            />
            {/* <div className="password-field">
                  <span>Password:</span>
                  <input type="text" maxlength="1" />
                  <input type="text" maxlength="1" />
                  <input type="text" maxlength="1" />
                  <input type="text" maxlength="1" />
                  <input type="checkbox" className="mybox" />
              </div> */}

            <button
              type="submit"
              style={{
                color: 'orange'
              }}
              className="create-room-modal-button btn1"
            >
              Create Table
            </button>
          </form>
        </div>
        <div
          className="curtain"
          onClick={() => this.setState({ showCreateRoomForm: false })}
        ></div>
      </div>
    );
  };

  logOut = () => {
    signout(() => {
      this.setState({ redirectToHome: true });
      this.props.socket.emit("logout");
      this.props.socket.disconnect();
      this.props.cleanSocket();
    });
  };

  adStatusCallback = (status) => {
    // This can contain whatever code you like. The err parameter will return the
    // following values (please DO NOT block callback thread or ad will fail):
    //	'ad-blocker' = an ad blocker was detected
    //	'network-error' = network connection not available
    //  'cors-error' = cross-origin error (try clearing browser cache)
    //  'no-zoneId' = the required zoneId value is missing
    //  'ad-started' = an ad has been loaded and is starting
    //  'fb-started' = a fallback has been started by fallback mode
    //	'ad-watched' = an ad was presented and ran successfully
    //  'fb-watched' = a fallback ad was presented and ran successfully
    //	'ad-interrupted' = ad was ended prior to 5 seconds (abnormal end)
    //	'ads-unavailable' = no ads were returned to the player
    //  'sys-closing' = the process has completed and the player is closing.

    if (status) console.log("Applixir status: " + status);
  };

  // invokeAppLixir = () => {
  //   let user = isAutheticated().user;
  //   window.invokeApplixirVideoUnit({
  //     zoneId: 3298, // the zone ID from the "Games" page
  //     devId: 4288, // optional: your developer ID if using s2s callback
  //     gameId: 5344, // optional: the ID for this game from the "Games" page for s2s callback
  //     custom1: user._id, // optional: custom1 value for s2s callback
  //     custom2: user, // optional: custom2 value for s2s callback
  //     dMode: 0, // 0 for no MD5 checksum, 1 for MD5 checksum (recommended)
  //     fallback: 1, // 0 for no fallbacks, 1 will show fallback ads when ads-unavailable
  //     adStatusCb: this.adStatusCallback, // optional: function to provide helpful user messages
  //   });
  // };

  handleWithdrawAmountChange = (e) => {
    let value = e.target.value.replace("-","");
    if (value < 0) return;
    if (value > this.state.total_chips) return;
    this.setState({ withdrawal_amount: value });
  };

  handleDepositAmountChange = (e) => {
    if (e.target.value < 0) return;
    // if (e.target.value > this.state.total_chips) return;
    this.setState({ deposit_amount_in_bitclout: e.target.value });
  };

  openWithdrawalForm = () =>
    this.setState({ withdrawalFormOpen: true, withdrawalSuccess: false, withdrawal_amount: 0 });
  closeWithdrawalForm = () => this.setState({ withdrawalFormOpen: false, withdrawalError: null });

  openDepositForm = () =>
    this.setState({ depositFormOpen: true, depositSuccess: false });
  closeDepositForm = () => this.setState({ depositFormOpen: false, withdrawal_amount: 0 });

  handleWithdrawalSubmit = () => {
    let token = JSON.parse(localStorage.getItem("jwt")).token;
    this.setState({ withdrawalLoading: true });
    fetch(`${API}/api/withdraw`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ amount: this.state.withdrawal_amount }),
    })
      .then((res) => {
        if(res.status === 200){
          return res.json()
        }else{
          let data = res.json()
          throw new Error(data.message || 'Something went wrong!')
        }
      })
      .then((res) => {
        console.log(res.data);
        this.setState({
          withdrawalLoading: false,
          // withdrawalFormOpen: false,
          total_chips: res.data.pokerLive.total_chips,
          withdrawalSuccess: true,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ withdrawalLoading: false, withdrawalError: error.message || 'Something went wrong!' });
      });
  };

  handleDepositSubmit = () => {
    let token = JSON.parse(localStorage.getItem("jwt")).token;
    this.setState({ depositLoading: true });
    fetch(`${API}/api/deposit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ amount: this.state.deposit_amount_in_bitclout }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.data);
        this.setState({
          depositLoading: false,
          depositFormOpen: false,
          depositSuccess: true,
        });
        this.props.history.push(
          `/deposit/${res.data._id}?amount=${res.data.amount}`
        );
      })
      .catch((error) => {
        console.log(error);
        this.setState({ depositLoading: false, depositFormOpen: false });
      });
  };

  renderDepositForm = () => (
    <Dialog
      open={this.state.depositFormOpen}
      onClose={this.closeDepositForm}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add Chips</DialogTitle>
      <DialogContent>
        {this.state.depositSuccess ? (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            Your Request has been saved, our team will transfer{" "}
            <strong>${this.state.withdrawal_amount}</strong> in your Bitclout
            Wallet
          </Alert>
        ) : (
          <React.Fragment>
            <DialogContentText>
              Note: 1 bitclout = 1000 Clout Poker Chips
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="deposit_amount"
              value={this.state.deposit_amount_in_bitclout}
              onChange={this.handleDepositAmountChange}
              label={`Amount in Bitclout`}
              type="number"
              fullWidth
            />
            {this.state.deposit_amount_in_bitclout < 1 ? null : (
              <DialogContentText>
                You'll get {this.state.deposit_amount_in_bitclout * 1000}{" "}
                CloutPoker Chips
              </DialogContentText>
            )}
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <React.Fragment>
          <Button onClick={this.closeDepositForm} color="primary">
            Cancel
          </Button>
          <Button
            onClick={this.handleDepositSubmit}
            color="primary"
            disabled={
              this.state.withdrawalLoading ||
              this.state.deposit_amount_in_bitclout < 1
            }
          >
            {this.state.withdrawalLoading ? <CircularProgress /> : "Next"}
          </Button>
        </React.Fragment>
      </DialogActions>
    </Dialog>
  );

  renderWithdrawalForm = () => (
    <Dialog
      open={this.state.withdrawalFormOpen}
      onClose={this.closeWithdrawalForm}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Withdraw Chips</DialogTitle>
      <DialogContent>
        {this.state.withdrawalSuccess ? (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            Your Request has been saved, our team will transfer{" "}
            <strong>{this.state.withdrawal_amount/1000} Bitclout</strong> in your
            Wallet
          </Alert>
        ) : 
        this.state.withdrawalError ? 
        <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {this.state.withdrawalError}
      </Alert>
        : (
          <React.Fragment>
            <DialogContentText>
              The Amount will be Transfered in your Bitclout Wallet within 1-3
              processing days. (1000 chips = 1 Bitclout)
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="amount"
              value={this.state.withdrawal_amount}
              onChange={this.handleWithdrawAmountChange}
              label={`Chips (max: ${this.state.total_chips})`}
              type="number"
              fullWidth
            />
            {this.state.withdrawal_amount < 1 ? null : (
              <DialogContentText>
                You'll get {this.state.withdrawal_amount / 1000}{" "}
                BitClouts
              </DialogContentText>
            )}
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        {this.state.withdrawalSuccess || this.state.withdrawalError?.length ? (
          <Button onClick={this.closeWithdrawalForm} color="primary">
            Close
          </Button>
        ) : (
          <React.Fragment>
            <Button onClick={this.closeWithdrawalForm} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.handleWithdrawalSubmit}
              color="primary"
              disabled={this.state.withdrawalLoading}
            >
              {this.state.withdrawalLoading ? <CircularProgress /> : "Withdraw"}
            </Button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );

  getUserData = () => {
    const user = JSON.parse(localStorage.getItem("jwt")).user;
    fetch(`${API}/user/${user._id}`)
      .then((response) => response.json())
      .then((data) => this.setState({ total_chips: data.total_chips }))
      .catch((err) => console.log(err));
  };

  fetchLobbyData = () => {
    fetch(`${API}/lobby-data`)
      .then((response) => response.json())
      .then((data) => {
        console.log("LOBBY DETAILS FECHED!! => ", data);

        this.setState({
          lobbyTables: data,
        });
      })
      .catch((err) => console.log(err));
  };

  renderLobbyData = () => {
    // console.log("RENDER LOBBY DATA");
    let rows = [];
    const { lobbyTables } = this.state;
    if (lobbyTables !== null) {
      // eslint-disable-next-line array-callback-return
      Object.values(lobbyTables).map((table) => {
        if (
          table !== null &&
          this.state.showPrivateTables === table.privateTable
        ) {
          rows.push(
            <tr
              key={table.id}
              onMouseOver={() => {
                this.setState({ hoveredTable: table });
              }}
              onMouseLeave={() => {
                this.setState({ hoveredTable: null });
              }}
            >
              <td>{table.name}</td>
              <td className={cx(styles["text-center"], styles["text-muted"])}>
                {table.playersSeatedCount}/5
              </td>
              <td className={cx(styles["text-center"], styles["text-muted"])}>
                {table.minBuyIn}/{table.maxBuyIn}
              </td>
              <td className={cx(styles["text-center"], styles["text-muted"])}>
                {table.privateTable ? "Private" : "Public"}
              </td>
              <td className={cx(styles["text-center"], styles["text-muted"])}>
                {table.id}
              </td>
              <td className={styles["text-right"]}>
                <a
                  type="submit"
                  to={`/table-5/${table.id}`}
                  className={cx(
                    styles["btn"],
                    styles["btn-green"],
                    styles["btn-sm"]
                  )}
                  onClick={() => {
                    if (table.privateTable)
                      this.setState({
                        showJoinRoomForm: true,
                        isPrivate: table.privateTable,
                        tableId: table.id,
                      });
                    else this.props.history.push(`/table-5/${table.id}`);
                  }}
                >
                  Join Room
                </a>
              </td>
            </tr>
          );
        }
      });
    }
    // console.log("ROWS: ", rows);
    return rows;
  };

  render() {
    return (
      <div>
        <div className={styles["header"]}>
          <div className={styles["header-amount"]}>
            <img src={chipAmountImg} alt="chips" />
            <span>{this.state.total_chips}$</span>
            <a>
              {/* <img onClick={()=>alert("hello there")} src={plusButtonImg} alt="Plus" /> */}
            </a>
          </div>
        </div>
        <div className={styles["poker-lobby"]}>
          {/* <div id="applixir_vanishing_div" style={{ zIndex: "500" }} hidden>
            <iframe id="applixir_parent" allow="autoplay"></iframe>
          </div> */}
          {/* <Redirect to="/lobby"></Redirect> */}
          <div className={styles["gotolobby"]}>
            <div className={styles["lobby-home"]}>
              <div
                className={cx(
                  styles["sidebar-nav"],
                  styles["register-sidebar"]
                )}
              >
                <div className={styles["game-brand"]}>
                  The Clout <div className={styles["brand-poker"]}>Poker</div>
                </div>
                <div className={styles["nav-links"]}>
                  <a
                    onClick={this.openDepositForm}
                    style={{ cursor: "pointer" }}
                  >
                    <ion-icon name="add-circle"></ion-icon> Add Chips
                  </a>
                  <a
                    onClick={this.openWithdrawalForm}
                    style={{ cursor: "pointer" }}
                  >
                    <ion-icon name="cash-outline"></ion-icon> Withdraw Chips
                  </a>
                  <a href="#">
                    <ion-icon name="document-text-outline"></ion-icon>{" "}
                    Instructions
                  </a>
                  <a href="#">
                    <ion-icon name="settings-outline"></ion-icon> Settings
                  </a>
                  <a href="#" onClick={this.logOut}>
                    <ion-icon name="power-outline"></ion-icon> Logout
                  </a>
                </div>
              </div>
              <div
                className={cx(
                  styles["room-container"],
                  styles["register-container"]
                )}
              >
                <div className={styles["col-3-2"]}>
                  <div className={styles["lobby-table"]}>
                    <div className={styles["lobby-room-lists"]}>
                      <div className={styles["lobby-actions"]}>
                        <div className={styles["btn-block"]}>
                          <a
                            onClick={() =>
                              this.setState({ showJoinRoomForm: true })
                            }
                            className={cx(
                              styles["btn"],
                              styles["btn-primary-filled"],
                              styles["btn-sm"]
                            )}
                          >
                            Join room
                          </a>
                          <a
                            onClick={() =>
                              this.setState({ showCreateRoomForm: true })
                            }
                            className={cx(
                              styles["btn"],
                              styles["btn-red-filled"],
                              styles["btn-sm"]
                            )}
                          >
                            Create Room
                          </a>
                        </div>
                        <div className={styles["filter"]}>
                          <form
                            className={styles["room-type-filter"]}
                            action="#"
                          >
                            <h3>Room filter:</h3>
                            {/* <p>
                              <input
                                type="radio"
                                id="test1"
                                name="radio-group"
                                checked
                              />
                              <label for="test1">All</label>
                            </p> */}
                            <p>
                              <input
                                type="radio"
                                id="public"
                                name="radio-group"
                                checked={this.state.showPrivateTables === false}
                                onChange={() =>
                                  this.setState({ showPrivateTables: false })
                                }
                              />
                              <label for="public">Public</label>
                            </p>
                            <p>
                              <input
                                type="radio"
                                id="private"
                                name="radio-group"
                                checked={this.state.showPrivateTables === true}
                                onChange={() =>
                                  this.setState({ showPrivateTables: true })
                                }
                              />
                              <label for="private">Private</label>
                            </p>
                          </form>
                        </div>
                      </div>
                      <table className={styles["room-lists"]}>
                        <thead>
                          <tr>
                            <th>Room name</th>
                            <th className={styles["text-center"]}>Players</th>
                            <th className={styles["text-center"]}>
                              Min/Max BuyIn
                            </th>
                            <th className={styles["text-center"]}>Room type</th>
                            <th className={styles["text-center"]}>Room Id</th>
                            <th className={styles["text-right"]}>
                              Game Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.renderLobbyData().length ? (
                            this.renderLobbyData()
                          ) : (
                            <tr>No Tables To show</tr>
                          )}
                          {/* {sampleData()} */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className={styles["col-3-1"]}>
                  {this.state.hoveredTable !== null && (
                    <>
                      <div className={styles["section-header"]}>
                        <h2>
                          {" "}
                          <span>Live</span> Table
                        </h2>
                      </div>
                      <div className={styles["stream-table"]}>
                        <div className={styles["player-seats"]}>
                          {this.state.hoveredTable.players[0] !== null &&
                            typeof this.state.hoveredTable.players[0] ===
                              "object" &&
                            this.state.hoveredTable.players[0].name && (
                              <div className={styles["player"]}>
                                <div className={styles["name"]}>
                                  {this.state.hoveredTable.players[0] &&
                                    this.state.hoveredTable.players[0]
                                      .originalName}
                                </div>
                                <div className={styles["profile-img"]}></div>
                                <div className={styles["player-money"]}>
                                  {this.state.hoveredTable.players[0] &&
                                    `$${this.state.hoveredTable.players[0].chipsInPlay}`}
                                </div>
                                {this.state.hoveredTable.players[0]
                                  .hasCards && (
                                  <div className={styles["hand-cards"]}>
                                    <img
                                      className={cx(
                                        styles["hand-card"],
                                        styles["fluid"]
                                      )}
                                      src={cardImg}
                                      alt=""
                                    />
                                    <img
                                      className={cx(
                                        styles["hand-card"],
                                        styles["fluid"]
                                      )}
                                      src={cardImg}
                                      alt=""
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          {this.state.hoveredTable.players[1] !== null &&
                            typeof this.state.hoveredTable.players[1] ===
                              "object" &&
                            this.state.hoveredTable.players[1].name && (
                              <div className={styles["player"]}>
                                <div className={styles["name"]}>
                                  {this.state.hoveredTable.players[1] &&
                                    this.state.hoveredTable.players[1]
                                      .originalName}
                                </div>
                                <div className={styles["profile-img"]}></div>
                                <div className={styles["player-money"]}>
                                  {this.state.hoveredTable.players[1] &&
                                    `$${this.state.hoveredTable.players[1].chipsInPlay}`}
                                </div>
                                <div className={styles["hand-cards"]}>
                                  <img
                                    className={cx(
                                      styles["hand-card"],
                                      styles["fluid"]
                                    )}
                                    src={cardImg}
                                    alt=""
                                  />
                                  <img
                                    className={cx(
                                      styles["hand-card"],
                                      styles["fluid"]
                                    )}
                                    src={cardImg}
                                    alt=""
                                  />
                                </div>
                              </div>
                            )}
                          {this.state.hoveredTable.players[2] !== null &&
                            typeof this.state.hoveredTable.players[2] ===
                              "object" &&
                            this.state.hoveredTable.players[2].name && (
                              <div className={styles["player"]}>
                                <div className={styles["name"]}>
                                  {this.state.hoveredTable.players[2] &&
                                    this.state.hoveredTable.players[2]
                                      .originalName}
                                </div>
                                <div className={styles["profile-img"]}></div>
                                <div className={styles["player-money"]}>
                                  {this.state.hoveredTable.players[2] &&
                                    `$${this.state.hoveredTable.players[2].chipsInPlay}`}
                                </div>
                                <div className={styles["hand-cards"]}>
                                  <img
                                    className={cx(
                                      styles["hand-card"],
                                      styles["fluid"]
                                    )}
                                    src={cardImg}
                                    alt=""
                                  />
                                  <img
                                    className={cx(
                                      styles["hand-card"],
                                      styles["fluid"]
                                    )}
                                    src={cardImg}
                                    alt=""
                                  />
                                </div>
                              </div>
                            )}
                          {this.state.hoveredTable.players[3] !== null &&
                            typeof this.state.hoveredTable.players[3] ===
                              "object" &&
                            this.state.hoveredTable.players[3].name && (
                              <div className={styles["player"]}>
                                <div className={styles["name"]}>
                                  {this.state.hoveredTable.players[3] &&
                                    this.state.hoveredTable.players[3]
                                      .originalName}
                                </div>
                                <div className={styles["profile-img"]}></div>
                                <div className={styles["player-money"]}>
                                  {this.state.hoveredTable.players[3] &&
                                    `$${this.state.hoveredTable.players[3].chipsInPlay}`}
                                </div>
                                <div className={styles["hand-cards"]}>
                                  <img
                                    className={cx(
                                      styles["hand-card"],
                                      styles["fluid"]
                                    )}
                                    src={cardImg}
                                    alt=""
                                  />
                                  <img
                                    className={cx(
                                      styles["hand-card"],
                                      styles["fluid"]
                                    )}
                                    src={cardImg}
                                    alt=""
                                  />
                                </div>
                              </div>
                            )}
                          {this.state.hoveredTable.players[4] !== null &&
                            typeof this.state.hoveredTable.players[4] ===
                              "object" &&
                            this.state.hoveredTable.players[4].name && (
                              <div className={styles["player"]}>
                                <div className={styles["name"]}>
                                  {this.state.hoveredTable.players[4] &&
                                    this.state.hoveredTable.players[4]
                                      .originalName}
                                </div>
                                <div className={styles["profile-img"]}></div>
                                <div className={styles["player-money"]}>
                                  {this.state.hoveredTable.players[4] &&
                                    `$${this.state.hoveredTable.players[4].chipsInPlay}`}
                                </div>
                                <div className={styles["hand-cards"]}>
                                  <img
                                    className={cx(
                                      styles["hand-card"],
                                      styles["fluid"]
                                    )}
                                    src={cardImg}
                                    alt=""
                                  />
                                  <img
                                    className={cx(
                                      styles["hand-card"],
                                      styles["fluid"]
                                    )}
                                    src={cardImg}
                                    alt=""
                                  />
                                </div>
                              </div>
                            )}
                        </div>
                        <div className={styles["table-cards"]}>
                          <img
                            src="images/card1.png"
                            alt=""
                            className="table-card"
                          />
                          <img
                            src="images/card1.png"
                            alt=""
                            className="table-card"
                          />
                          <img
                            src="images/card1.png"
                            alt=""
                            className="table-card"
                          />
                          <img
                            src="images/card1.png"
                            alt=""
                            className="table-card"
                          />
                          <img
                            src="images/card1.png"
                            alt=""
                            className="table-card"
                          />
                        </div>
                        <div className={styles["poker-table"]}>
                          <img className="fluid" src={tableImg} alt="" />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            {this.createRoomForm()}
            {this.joinRoomForm()}
            {this.renderWithdrawalForm()}
            {this.renderDepositForm()}
            {this.state.success && (
              <Redirect to={`/table-5/${this.state.tableId}`} />
            )}
          </div>
        </div>
      </div>
    );
  }
}
