import React from 'react';
import "./restrictMobile.style.css";

export default function RestrictMobile() {
    return (
        <div className="restrictMobile">
            <p> You can not Play this game in a Mobile Devices with widh less than 700px . Game is only available for wider screens.</p>
        </div>
    )
}
