import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./admin.style.css";

export default class OnlinePlayers extends Component {
  constructor(props){
    super(props);
    this.state = {
      livePlayers: 0
    }
  }

  componentDidMount(){
    this.props.socket.emit("iamAdmin",(data)=>{
      this.setState({livePlayers:data});
    });

    this.props.socket.on("onlinePlayersCount",(data)=>{
      this.setState({livePlayers:data});
    })
  }

  render() {
    return (
      <div className="counterDiv">
        <h1>Instamuse Poker</h1>
        <h3>Total Online Players: </h3>
        <div className="totalPlayersCounterContainer">
          <div className="counter">
            <span id="activePlayersCount">{this.state.livePlayers}</span>
          </div>
        </div>
        {/* <div className="viewPlayersContainer">
          <Link to="">view All Players</Link>{" "}
        </div> */}
      </div>
    );
  }
}

// import React from "react";
// import { Link } from "react-router-dom";
// import { useState } from "react";
// import "./admin.style.css";

// export default function OnlinePlayers(socket) {

//   const [state, setState] = useState({
//     livePlayers: 0,
//   });

// //   socket.emit("iamAdmin");

//   socket.on("onlinePlayersCount",(data)=>{
//       setState({livePlayers:data});
//   })

//   const { livePlayers } = state;

//   return (
//     <div>
//       <h1>Instamuse Poker</h1>
//       <h3>Total Online Players: </h3>
//       <div className="totalPlayersCounterContainer">
//         <div className="counter">
//           <span id="activePlayersCount">{livePlayers}</span>
//         </div>
//       </div>
//       <div className="viewPlayersContainer">
//         <Link to="">view All Players</Link>{" "}
//       </div>
//     </div>
//   );
// }
