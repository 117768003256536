import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import "../../App.css";
import "../../enk.css";
// import ForgotPassword from "./forgotPassword.component";
// import GoogleLogin from "react-google-login";
// import FacebookLogin from "react-facebook-login";
import BitcloutLogin from "react-bitclout-login";

import {
  signin,
  authenticate,
  // isAutheticated,
  // googleSignIn,
  // faceBookSignIn,
  verifyBitclout,
} from "../../auth/helper";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loginButton: {
    backgroundColor: "#FFFFFF",
    textTransform: "none",
    width: "50%",
    margin: "2%",
  },
}));

const Spinner = () => (
  <div className="spinner">
    <div className="rect1"></div>
    <div className="rect2"></div>
    <div className="rect3"></div>
    <div className="rect4"></div>
    <div className="rect5"></div>
  </div>
);

const Signin = () => {
  const [values, setValues] = useState({
    username: "",
    password: "",
    error: "",
    loading: false,
    didRedirect: false,
    displayForgotPassword: false,
  });

  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const { username, password, error, didRedirect, displayForgotPassword } =
    values;
  // const { user } = isAutheticated();

  const handleChange = (name) => (event) => {
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: false, loading: true });
    setLoading(true);
    signin({ username, password })
      .then((data) => {
        setLoading(false);
        if (data.error) {
          setValues({ ...values, error: data.error, loading: false });
          if (data.error === "incorrect password") {
            setValues({ ...values, displayForgotPassword: true });
          }
        } else {
          authenticate(data, () => {
            setValues({
              ...values,
              didRedirect: true,
            });
            console.log("DIDREDIRECT VALUE: ", didRedirect);
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("signin request failed", err);
      });
  };

  const performRedirect = () => {
    // if(isAutheticated()){
    //   return (<Redirect to="/" />)
    // }
    if (didRedirect) {
      return <Redirect to="/lobby" />;
    }
  };

  // const loadingMessage = () => {
  //   return (
  //     loading && (
  //       <div className="alert alert-info">
  //         <h2>Loading...</h2>
  //       </div>
  //     )
  //   );
  // };

  // const errorMessage = () => {
  //   return (
  //     <div className="row">
  //       <div className="col-md-6 offset-sm-3 text-left">
  //         <div
  //           className="alert alert-danger"
  //           style={{ display: error ? "" : "none", color: "red" }}
  //         >
  //           <h4>{error}</h4>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const responseClout = (response) => {
    console.log("Success: ", response);
    setValues({ ...values, error: false, loading: true });
    setLoading(true);
    verifyBitclout(response)
      .then((data) => {
        setLoading(false);
        if (data.error) {
          setValues({ ...values, error: data.error, loading: false });
          if (data.error === "incorrect password") {
            setValues({ ...values, displayForgotPassword: true });
          }
        } else {
          authenticate(data, () => {
            setValues({
              ...values,
              didRedirect: true,
            });
            console.log("DIDREDIRECT VALUE: ", didRedirect);
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("signin request failed", err);
      });
  };

  const failureResponse = (response) => {
    console.log("Failure: ", response);
  };

  const accessLevel = 3;
  const JWT = true;

  const newSignInForm = () => {
    return (
      <div className="">
        <div className="register">
          <div className="register-wrapper">
            <div className="register-box">
              <div className="reg-box-title">
                <span>W</span>
                <span>e</span>
                <span>l</span>
                <span>c</span>
                <span>o</span>
                <span>m</span>
                <span>e</span>
              </div>
              <div className="game-brand">
                <span>to</span> The Clout{" "}
                <div className="brand-poker">Poker</div>
              </div>
              <div className="form">
                <div className="form-group">
                  <input
                    onChange={handleChange("username")}
                    value={username}
                    type="text"
                    placeholder="Username"
                    className="form-input"
                  />
                </div>
                <div className="form-group">
                  <input
                    onChange={handleChange("password")}
                    value={password}
                    type="password"
                    placeholder="Password"
                    className="form-input"
                  />
                </div>
                <div className="form-group">
                  <button
                    onClick={onSubmit}
                    type="submit"
                    className={loading ? "btn" : "btn btn-primary"}
                    style={
                      loading
                        ? {
                            backgroundColor: "transparent",
                            cursor: "default",
                            border: "1px solid #00A4BA",
                          }
                        : {}
                    }
                    disabled={loading}
                  >
                    {loading ? <Spinner /> : "Login"}
                  </button>
                </div>
                {/* <div className="form-block">
                  {facebookLoginBtn()}
                  {googleLoginBtn()}
                </div> */}
                {/* <div className="form-group">
                  <Link to="/user/signup" className="btn btn-ghost">
                    Register
                  </Link>
                </div> */}
                <div style={{ color: "black" }}>OR</div>
                <BitcloutLogin
                  accessLevel={accessLevel}
                  onSuccess={responseClout}
                  onFailure={failureResponse}
                  JWT={JWT}
                  customization={{
                    className: classes.loginButton,
                    onclick: () => setValues({ ...values, loading: true }),
                  }}
                  // customIcon={<LockOpenIcon/>}
                />
              </div>
              <svg
                className="register-svg"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <filter id="f2">
                    <feOffset
                      result="offOut"
                      in="SourceGraphic"
                      dx="0"
                      dy="0"
                    />
                    <feGaussianBlur
                      result="blurOut"
                      in="offOut"
                      stdDeviation="5"
                    />
                    <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                  </filter>
                </defs>
                <rect
                  class="light-1"
                  rx="20"
                  ry="20"
                  x="20"
                  y="20"
                  filter="url(#f2)"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // const heading = () => {
  //   return (
  //     <div>
  //       <h1 className="heading">Instamuse Poker Live</h1>
  //       <h2 className="heading2">Login</h2>
  //     </div>
  //   );
  // };

  // const betaMessage = () => {
  //   return (
  //     <div style={{ color: "yellow", fontWeight: "600", fontSize: "1.1em" }}>
  //       <p>
  //         welcome to the Beta version of Poker by Instamuse. Please note this
  //         game is ment to be played on a desktop or laptop(not on mobile)
  //       </p>
  //       <p>
  //         if you are new, please signup first then log in with your signup
  //         credentials.
  //       </p>
  //     </div>
  //   );
  // };

  // const responseSuccessGoogle = (response) => {
  //   setValues({ ...values, loading: true });
  //   console.log(response);
  //   googleSignIn(response.tokenId)
  //     .then((data) => {
  //       if (data.error) {
  //         setValues({ ...values, error: data.error, loading: false });
  //         if (data.error === "incorrect password") {
  //           setValues({ ...values, displayForgotPassword: true });
  //         }
  //       } else {
  //         console.log("authenticated by google : ", data);
  //         authenticate(data, () => {
  //           setValues({
  //             ...values,
  //             didRedirect: true,
  //           });
  //         });
  //       }
  //     })
  //     .catch((err) => console.log("signin request failed", err));
  // };

  // const responseErrorGoogle = (response) => {
  //   console.log(response);
  // };

  // const googleLoginBtn = () => {
  //   return (
  //     <GoogleLogin
  //       clientId="110582449302-r2a4c1ikqsbj8umv4f31jgn3o529n09v.apps.googleusercontent.com"
  //       buttonText="Login with Mail"
  //       onSuccess={responseSuccessGoogle}
  //       onFailure={responseErrorGoogle}
  //       // theme="dark"
  //       icon={false}
  //       className="btn btn-social btn-gmail"
  //       cookiePolicy={"single_host_origin"}
  //     />
  //   );
  // };

  // const responseFacebook = (response) => {
  //   console.log(response);
  //   const { accessToken, userID } = response;
  //   faceBookSignIn({ accessToken, userID })
  //     .then((data) => {
  //       if (data.error) {
  //         setValues({ ...values, error: data.error, loading: false });
  //         if (data.error === "incorrect password") {
  //           setValues({ ...values, displayForgotPassword: true });
  //         }
  //       } else {
  //         console.log("authenticated by Facebook : ", data);
  //         authenticate(data, () => {
  //           setValues({
  //             ...values,
  //             didRedirect: true,
  //           });
  //         });
  //       }
  //     })
  //     .catch((err) => console.log("signin request failed", err));
  // };

  // const fbBttonClicked = () => {
  //   setValues({ ...values, loading: true });
  // };

  // const facebookLoginBtn = () => {
  //   return (
  //     <FacebookLogin
  //       // textButton="  Login with Facebook"
  //       icon="fa-facebook"
  //       appId="278157200064443"
  //       autoLoad={false}
  //       fields="name,email,picture"
  //       cssClass="btn btn-social btn-facebook"
  //       onClick={fbBttonClicked}
  //       callback={responseFacebook}
  //     />
  //   );
  // };

  return (
    <div>
      {/* {heading()} */}
      {/* {betaMessage()} */}
      {/* {loadingMessage()}
      {errorMessage()} */}
      {/* {googleLoginBtn()}
      {facebookLoginBtn()} */}
      {newSignInForm()}
      {performRedirect()}
      {/* <p className="text-white text-center">{JSON.stringify(values)}</p> */}
    </div>
  );
};

export default Signin;
