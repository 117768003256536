import React from "react";
import "./betaMessage.style.css"

export default function BetaMessage() {
  return (
    <div className="wrap">
      <p className="red">
        Instaumuse poker is now available for beta
        testing only. Please report any bugs you see at{" "}
        <a href="mailto:admin@instamuse.net">admin@instamuse.net</a>. Thank you
        and enjoy.
      </p>
    </div>
  );
}
