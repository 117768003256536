import React, { Fragment } from "react";
import { PlayState, Tween, Timeline } from "react-gsap";
import "../Table/style.css";
import pic from "../../images/no-img.png";
import checkmark from "../../images/checkmark.png";
import passCreatorQR from "../../images/passcreator.png";

const getCardClass = (seat, card, mySeat, myCards, player) => {
  if (mySeat == seat) {
    return `card-${myCards[card]}`;
  } else if (
    typeof player !== "undefined" &&
    player &&
    player.cards &&
    player.cards[card]
  ) {
    return `card-${player.cards[card]}`;
  } else {
    return "card-back";
  }
};

const hasCards = function (player) {
  if (typeof player !== "undefined" && player !== null) {
    if (player.hasCards) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const hasBet = function (player) {
  if (typeof player !== "undefined" && player !== null) {
    if (player.bet > 0) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

// const showInfo = (player) => {
//   console.log(player);
// };

const isEmpty = (player) => {
  if (typeof player === "undefined") return true;
  if (player === null) return true;
  try {
    if (!player.hasOwnProperty("name")) return true;
  } catch (err) {
    console.log(err);
  }
  return false;
};

const ballAnimations = [
  { x: '200px', y: "-200px", opacity: 0 },
  { x: '-200px', y: "200px", opacity: 0 },
  { x: '200px', y: "200px", opacity: 0 },
  { x: '200px', y: "0px", opacity: 0 },
  { x: '0px', y: "200px", opacity: 0 },
  { x: '0px', y: "-200px", opacity: 0 },
  { x: '-200px', y: "0px", opacity: 0 },
  { x: '-200px', y: "-200px", opacity: 0 },
];

const Seat = function ({
  sitIn,
  seatIndex,
  pos,
  player,
  activeSeat,
  myCards,
  mySeat,
  sittingIn,
  moveMoneyAnimation,
  showInfo,
  winnerSeat,
}) {

  const renderTween = (to) => {
    return (
      <Tween
        playState={winnerSeat === seatIndex ? PlayState.play : PlayState.stop}
        // playState={PlayState.play}
        to={to}
        stagger={0.2}
        repeat={5}
      >
        <div
          style={{
            position: "absolute",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            background: "red",
          }}
        />
        <div
          style={{
            position: "absolute",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            background: "green",
          }}
        />
        <div
          style={{
            position: "absolute",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            background: "yellow",
          }}
        />
        <div
          style={{
            position: "absolute",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            background: "blue",
          }}
        />
        <div
          style={{
            position: "absolute",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            background: "orange",
          }}
        />
      </Tween>
    );
  }

  return (
    <div>
      {/* <button className="sitin" onClick={sitIn}>
      sitIn{pos}
      </button> */}
      <div
        className={
          (sittingIn && mySeat !== seatIndex && isEmpty(player)
            ? "hide"
            : "seat " + pos) + (pos === "player4" && sittingIn ? " main" : "")
        }
        onClick={
          typeof player !== "undefined" && player !== null && player.name
            ? () => {
              showInfo(player);
            }
            : sitIn
        }
      >
        <div style={{ position: 'absolute', top: "45%", left: "45%", zIndex: 2 }}>
          {/* {
            winnerSeat === seatIndex ?
            <img height={70} width={150} src={require("../../images/winner.png")} /> : 
            null
          } */}
          {renderTween(ballAnimations[0])}
          {renderTween(ballAnimations[1])}
          {renderTween(ballAnimations[2])}
          {renderTween(ballAnimations[3])}
          {renderTween(ballAnimations[4])}
          {renderTween(ballAnimations[5])}
          {renderTween(ballAnimations[6])}
          {renderTween(ballAnimations[7])}
          {/* <Tween 
            playState={
              winnerSeat === seatIndex ? PlayState.play : PlayState.stop
            }
            to={{ x: '100px', y: "-70px", opacity: 0 }} stagger={0.2}>
            <div style={{position: "absolute", borderRadius: "50%", width: '20px', height: '20px', background: 'red' }} />
            <div style={{position: "absolute", borderRadius: "50%", width: '20px', height: '20px', background: 'green' }} />
            <div style={{position: "absolute", borderRadius: "50%", width: '20px', height: '20px', background: 'yellow' }} />
            <div style={{position: "absolute", borderRadius: "50%", width: '20px', height: '20px', background: 'blue' }} />
            <div style={{position: "absolute", borderRadius: "50%", width: '20px', height: '20px', background: 'orange' }} />
          </Tween>
          <Tween 
             playState={
              winnerSeat === seatIndex ? PlayState.play : PlayState.stop
            }
          to={{ x: '100px', y: "70px", opacity: 0 }} stagger={0.2}>
            <div style={{position: "absolute", borderRadius: "50%", width: '20px', height: '20px', background: 'red' }} />
            <div style={{position: "absolute", borderRadius: "50%", width: '20px', height: '20px', background: 'green' }} />
            <div style={{position: "absolute", borderRadius: "50%", width: '20px', height: '20px', background: 'yellow' }} />
            <div style={{position: "absolute", borderRadius: "50%", width: '20px', height: '20px', background: 'blue' }} />
            <div style={{position: "absolute", borderRadius: "50%", width: '20px', height: '20px', background: 'orange' }} />
          </Tween> */}
        </div>
        {/* <div className="user-final-result user-winner">
              {this.state.screenName}
            </div> */}
        <span
          className="player-name"
        // onClick={
        //   typeof player !== "undefined" && player !== null && player.name
        //     ? ()=>{showInfo(player)}
        //     : sitIn
        // }
        >
          {typeof player !== "undefined" && player !== null && player.name
            ? player.originalName
            : ""}
        </span>
        <svg height="138" width="138">
          <Tween
            playState={
              activeSeat === seatIndex ? PlayState.play : PlayState.stop
            }
            from={{
              svgDraw: 0,
            }}
            to={{
              svgDraw: 1,
            }}
            duration={60}
          >
            <circle
              cx="69"
              cy="69"
              r="67"
              stroke="rgb(163,163,0)"
              strokeWidth="4"
              fill="transparent"
            />
          </Tween>
        </svg>
        <img
          style={{ border: "4px solid rgba(84,163,176,.6)" }}
          src={pic}
          alt="Player"
          onClick={
            typeof player !== "undefined" && player !== null && player.name
              ? () => {
                showInfo(player);
              }
              : sitIn
          }
        />
        {/* video stream:  */}
        {typeof player !== "undefined" && player !== null && player.name && (
          <div>
            <div
              key={player.name}
              className="remote-video"
              id={`agora_remote ${player.name}`} // here add the QR code
            >
              {/* <span
              id={`${streamId}-audio`}
              onClick={
                ()=>{
                  stream.stop();
                  stream.play("agora_remote " + streamId, {muted:false})
                  document.getElementById(`${streamId}-audio`).remove();
                }  
              }
            >
              click here to enable audio
            </span> */}
            </div>
          </div>
        )}
        {
          !(sittingIn && mySeat !== seatIndex && isEmpty(player)) &&
          pos === "player4" &&
          sittingIn && (
            <div>
              <div
                id="agora_local"
                className={"player"}
              />
              <div id='qr-code'>
                <img
                  alt="pass-creator QR"
                  height={100}
                  width={100}
                  src={passCreatorQR}
                  style={{ height: 100, width: 100, zIndex: 100 }}
                />
              </div>
            </div>
          )}
        <div className="action">
          {/* <Timeline
            playState={PlayState.play}
            target={
              <Fragment>
                <img src={checkmark} alt="Checkmark" />
                <span>{player ? player.bet : 0}$</span>
              </Fragment>
            }
          > */}
          {/* <Tween 
              from={{x:0, opacity:0}}
              playState={hasBet(player)?PlayState.play:PlayState.stop}
              target={0}
            /> 
            <Tween 
              from={{width:0, opacity:0}}
              playState={hasBet(player)?PlayState.play:PlayState.stop}
              target={1}
            /> */}
          <Tween
            from={{ opacity: 1 }}
            to={moveMoneyAnimation}
            playState={hasBet(player) ? PlayState.stop : PlayState.play}
            duration={2}
          >
            <img src={checkmark} alt="Checkmark" />
            <span>${player ? player.bet : 0}</span>
          </Tween>
          {/* </Timeline> */}
        </div>
        <div className={hasCards(player) ? "cards" : "hide"}>
          <Tween
            playState={hasCards(player) ? PlayState.play : PlayState.stop}
            from={{ top: "-=1000" }}
            stagger={0.1}
            duration={0.3}
          >
            <div
              className={
                "card " + getCardClass(seatIndex, 0, mySeat, myCards, player)
              }
            />
            <div
              className={
                "card " + getCardClass(seatIndex, 1, mySeat, myCards, player)
              }
            />
          </Tween>
        </div>
        {/* <div className="user-cards-main">
              <img src="images/card1.png" alt="Card" />
              <img src="images/card2.png" alt="Card" />
            </div> */}
        <span className={sittingIn ? "balance" : "hide"}>
          ${player ? player.chipsInPlay : "0"}
        </span>
      </div>
    </div>
  );
};

export default Seat;
