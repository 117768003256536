import React, { Component } from "react";
import AgoraRTC from "agora-rtc-sdk";
import "./call.style.css";
import { getToken } from "../../auth/helper";
let client = AgoraRTC.createClient({ mode: "live", codec: "h264" });
const isChrome =
  !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

const APP_ID = "0e182fe0da0e449095f14ba0c5919856"; //agora appId
AgoraRTC.Logger.setLogLevel(AgoraRTC.Logger.NONE);
export default class Call extends Component {
  localStream = AgoraRTC.createStream({
    streamID: this.props.streamId,
    audio: true,
    video: true,
    screen: false,
  });

  state = {
    remoteStreams: {},
  };

  componentDidMount() {
    if (isChrome) {
      this.localStream.setVideoProfile("240p_3");
      console.log("chrome detected !");
    } else {
      this.localStream.setVideoProfile("480p_1");
      console.log("chrome not detected !");
    }
    // if(this.props.sittingIn){
    //   // this.initLocalStream();
    //   // this.initClient();
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.enableLocalVideo === false &&
      this.props.enableLocalVideo === true
    ) {
      // alert("Local Video is Enabled.")
      this.initClient();
    }
    // if (this.props.channel !== "" && this.props.sittingIn) {
    // }
    if (this.props.channel === false) {
      this.leaveChannel();
      try {
        this.localStream.close();
        this.localStream.stop();
      } catch (err) {
        console.log(err);
      }
    }
    if (this.props.blockPlayer) {
      console.log("remote stream to be blocked: ", this.props.blockPlayer);
      try {
        this.state.remoteStreams[this.props.blockPlayer.name].stop();
      } catch (err) {
        console.log("player block failed: ", err);
      }
      // console.log(this.state.remoteStreams[this.props.blockPlayer.name]);
      this.props.playerBlocked();
    }
  }

  initLocalStream = () => {
    let me = this;
    me.localStream.init(
      function () {
        console.log("getUserMedia successfully");
        me.localStream.play("agora_local");

        client.publish(me.localStream, function (err) {
          console.log("Publish local stream error: " + err);
        });
      },
      function (err) {
        console.log("getUserMedia failed", err);
        if (err.msg == "NotAllowedError") {
          // Explain why you need permission and how to update the permission setting
          window.alert(
            "we need to access your webcam to provide you and other players a Real Poker experience, please allow the stream."
          );
        }
      }
    );
  };

  initClient = () => {
    let me = this;
    client.init(
      APP_ID,
      function () {
        console.log("AgoraRTC client initialized");
        me.subscribeToClient();
        me.joinChannel();
      },
      function (err) {
        console.log("AgoraRTC client init failed", err);
      }
    );
  };

  subscribeToClient = () => {
    let me = this;
    client.on("stream-added", me.onStreamAdded);
    client.on("stream-subscribed", me.onRemoteClientAdded);

    client.on("stream-removed", me.onStreamRemoved);

    client.on("peer-leave", me.onPeerLeave);
  };

  onStreamAdded = (evt) => {
    let me = this;
    let stream = evt.stream;
    console.log("New stream added: " + stream.getId());
    me.setState(
      {
        remoteStreams: {
          ...me.state.remoteStreams,
          [stream.getId()]: stream,
        },
      },
      () => {
        // Subscribe after new remoteStreams state set to make sure
        // new stream dom el has been rendered for agora.io sdk to pick up
        client.subscribe(stream, function (err) {
          console.log("Subscribe stream failed", err);
        });
      }
    );
  };

  joinChannel = () => {
    let me = this;
    //get the token first
    getToken({ channelName: me.props.channel, uid: me.props.streamId })
      .then((res) => res.json())
      .then((res) => {
        const {token} = res.data;
        client.join(
          token, // token will go here....
          me.props.channel,
          me.props.streamId,
          function (uid) {
            console.log("User " + uid + " join channel successfully");

            me.initLocalStream();

            client.on("stream-published", function (evt) {
              console.log("Publish local stream successfully");
            });
          },
          function (err) {
            console.log("Join channel failed", err);
          }
        );
      });
    // const token = this.generateToken();
  };

  onRemoteClientAdded = (evt) => {
    let me = this;
    let remoteStream = evt.stream;
    client.setRemoteVideoStreamType(remoteStream, 1);
    me.state.remoteStreams[remoteStream.getId()].play(
      "agora_remote " + remoteStream.getId()
    );
  };

  onStreamRemoved = (evt) => {
    let me = this;
    let stream = evt.stream;
    if (stream) {
      let streamId = stream.getId();
      let { remoteStreams } = me.state;

      stream.stop();
      delete remoteStreams[streamId];

      me.setState({ remoteStreams });

      console.log("Remote stream is removed " + stream.getId());
    }
  };

  onPeerLeave = (evt) => {
    let me = this;
    let stream = evt.stream;
    if (stream) {
      let streamId = stream.getId();
      let { remoteStreams } = me.state;

      stream.stop();
      delete remoteStreams[streamId];

      me.setState({ remoteStreams });

      console.log(evt.uid + " leaved from this channel");
    }
  };

  leaveChannel = () => {
    try {
      Object.keys(this.state.remoteStreams).map((key) => {
        this.state.remoteStreams[key].close();
        this.state.remoteStreams[key].stop();
        let d = document.getElementById(`agora_remote ${key}`);
        d.innerHTML = "";
      });
    } catch (err) {
      console.log(err);
    }
    client.leave(
      function () {
        console.log("client leaves channel");
        //……
      },
      function (err) {
        console.log("client leave failed ", err);
        //error handling
      }
    );
    console.log("CHANNEL LEAVE CALLED !!");
  };

  render() {
    return (
      <div>
        {/* <div
          id="agora_local"
          className={this.props.channel ? "player" : "hide"}
        /> */}
        {/* {Object.keys(this.state.remoteStreams).map((key) => {
          let stream = this.state.remoteStreams[key];
          let streamId = stream.getId();

          let player = this.props.players.findIndex(
            (player) => player !== null && player.name == streamId
          );
          let pos = this.props.positions.findIndex((pos) => pos == player);
          console.log(`PLAYER: ${player}, pos: ${pos}`);

          return (
            <div>
              <div
              key={streamId}
              className={`player pos${pos + 1}`}
              id={`agora_remote ${streamId}`}
              style={{
                position: "absolute",
                zIndex: "3",
                width: "130px",
                height: "130px",
              }}
              >
                <span
                  id={`${streamId}-audio`}
                  onClick={
                    ()=>{
                      stream.stop();
                      stream.play("agora_remote " + streamId, {muted:false})
                      document.getElementById(`${streamId}-audio`).remove();
                    }  
                  }
                >
                  click here to enable audio
                </span>
              </div>
            </div>
          );
        })} */}
      </div>
    );
  }
}
